import React from 'react'
import { useNavigate } from 'react-router'
import bkashIcon from '../../../assets/payment/bKash.png'
import makePaymentIcon from '../../../assets/payment/makepayment.png'
import classes from './ContactUsPopUp.module.css'

export default function ContactUsPopUp(props) {
    const { activePopUp, setActivePopUp, payment } = props
    const navigate = useNavigate()
    // const [clicked, setClicked] = useState(false)

    if (activePopUp) {
        document.body.classList.add(classes.activeModel)
    } else {
        document.body.classList.remove(classes.activeModel)
    }

    return (
        <div className={classes.ContactUsPopUp}>
            <div>
                <p>You have successfully Signed Up!</p>
            </div>
            <div>
                <p>
                    Due Payment: <span style={{ color: 'rgb(255, 53, 53)' }}>BDT {payment}</span>
                </p>
                <img src={bkashIcon} alt="" />
            </div>
            <div>
                <p>
                    bKash Account Number: <span style={{ fontWeight: 'bold' }}>+880-1727611963</span> (Merchant)
                </p>
                <p>
                    Please add <span style={{ fontWeight: 'bold' }}>DT</span> in the reference
                </p>
                <img src={makePaymentIcon} alt="" />
                <p>For any query please contact: +880-1792128343</p>
                <button
                    onClick={() => {
                        setActivePopUp(false)
                        navigate('/login')
                    }}>
                    Okay
                </button>
            </div>
        </div>
    )
}
