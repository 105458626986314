import React, { useEffect } from 'react'
import { useState } from 'react'
// import { SelectDashboard } from '../../contexts/allContext'
import AllDashboards from './AllDashboards/AllDashboards'
import classes from './Dashboard.module.css'

export default function Dashboard(props) {
    const [clicked, setClicked] = useState('Company')
    const [popExit, setPopExit] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    // const context = useContext(SelectDashboard)
    // console.log(context)
    return (
        <>
            <div className={classes.dashboard}>
                <p>Welcome to Country's First Data Portal for</p>
                <p>Economic Data, Business Data & Company Data</p>
                <div className={classes.overview}>
                    <div>
                        <p>120,000+</p>
                        <p> DATA SERIES</p>
                    </div>
                    <div>
                        <p>500+</p>
                        <p> COMPANIES</p>
                    </div>
                    <div>
                        <p>50+</p>
                        <p>SECTORS</p>
                    </div>
                    <div>
                        <p>650+</p>
                        <p> SOURCES</p>
                    </div>
                    <div className={popExit ? classes.popExit : classes.popUpContact}>
                        <button onClick={() => setPopExit(!popExit)}>X</button>
                        <p id={classes.popUpP}>
                            Not finding the right Data? <button>Call Us</button>
                        </p>
                    </div>
                </div>
                {/* &#8250; */}

                <div className={classes.btnDashboard}>
                    <div id={classes.please_select}>Select Dashboard</div>
                    <button
                        className={clicked === 'Company' ? classes.btnActive : ''}
                        onClick={() => setClicked('Company')}>
                        <a href="#Dashboard">Company Dashboard</a>
                        <p className={clicked === 'Company' ? classes.btnActiveP : ''}></p>
                    </button>
                    <button
                        className={clicked === 'Industry' ? classes.btnActive : ''}
                        onClick={() => setClicked('Industry')}>
                        <a href="#Dashboard">Industry Dashboard</a>
                        <p className={clicked === 'Industry' ? classes.btnActiveP : ''}></p>
                    </button>
                    <button
                        className={clicked === 'Economic' ? classes.btnActive : ''}
                        onClick={() => setClicked('Economic')}>
                        <a href="#Dashboard">Economic Dashboard</a>
                        <p className={clicked === 'Economic' ? classes.btnActiveP : ''}></p>
                    </button>
                </div>
                {clicked === 'Company' && (
                    <AllDashboards
                        dash="Company"
                        downloadCount={props.downloadCount}
                        downloadLimit={props.downloadLimit}
                        handleUpdateDownload={props.handleUpdateDownload}
                        setCheck={props.setCheck}
                    />
                )}
                {clicked === 'Industry' && (
                    <AllDashboards
                        dash="Industry"
                        downloadCount={props.downloadCount}
                        downloadLimit={props.downloadLimit}
                        handleUpdateDownload={props.handleUpdateDownload}
                        setCheck={props.setCheck}
                    />
                )}
                {clicked === 'Economic' && (
                    <AllDashboards
                        dash="Economic"
                        downloadCount={props.downloadCount}
                        downloadLimit={props.downloadLimit}
                        handleUpdateDownload={props.handleUpdateDownload}
                        setCheck={props.setCheck}
                    />
                )}

                {/* <LineChart /> */}
            </div>
        </>
    )
}
