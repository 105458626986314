import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { Auth } from '../../../contexts/allContext'
import { api, frontEnd } from '../../../utils/apiToken'
// import SelectDropdown from '../../SelectDropdown/SelectDropdown'
import BarChart from '../BarChart/BarChart'
import BarChartBudget from '../BarChart/BarChartBudget'
import BarChartMonthly from '../BarChart/BarChartMonthly'
import LineChart from '../LineChart/LineChart'
import LineChartMonthly from '../LineChart/LineChartMonthly'
import ScatterChart from '../ScatterChart/ScatterChart'
import ScatterChartMonthly from '../ScatterChart/ScatterChartMonthly'
import { SelectData } from '../SelectData/SelectData.jsx'
import classes from './AllDashboards.module.css'
import Overview from './Overview/Overview'

export default function AllDashboards(props) {
    const { stateAuth } = useContext(Auth)
    let token = stateAuth.token

    // Recently added indicators
    // const company_indicator = ['Revenue', 'Current Assets', 'Claim Paid']
    // const industry_indicator = ['Mobile Internet User', 'Carpet Export', 'Urea Import']
    // const economic_indicator = ['Export - Total', 'Import (Others)', 'Account Balance']

    // show dashboard name
    const { dash } = props

    // To export data as csv
    const [dataForExport, setDataForExport] = useState([])

    // To set variable value of chart
    const [dataValueY, setDataValueY] = useState([])
    const [dataValueX, setDataValueX] = useState([])
    const [legendY, setLegendY] = useState([])
    const [legendX, setLegendX] = useState([])

    const [dataValueMonthY, setDataValueMonthY] = useState({
        jan: [],
        feb: [],
        mar: [],
        apr: [],
        may: [],
        jun: [],
        jul: [],
        aug: [],
        sep: [],
        oct: [],
        nov: [],
        dec: [],
    })
    const [dataValueMonthX, setDataValueMonthX] = useState([])

    // search
    // const [searchEconomic, setSearchEconomic] = useState('')
    // const [searchCompany, setSearchCompany] = useState('')
    // const [searchIndustry, setSearchIndustry] = useState('')

    // To remove selected option
    // const [selectedOption, setSelectedOption] = useState(false)

    // select options economic indicators
    const [economicIndicatorOptions, setEconomicIndicatorOptions] = useState([])

    // selected option economic indicator
    const [economicIndicatorSelect, setEconomicIndicatorSelect] = useState([])

    // select options company and sector
    const [companyOptions, setCompanyOptions] = useState([])
    const [industryOptions, setIndustryOptions] = useState([])

    // selected company and industry_sector
    const [companySelect, setCompanySelect] = useState('')
    const [industrySelect, setIndustrySelect] = useState('')

    // select options for indicators of company and industry
    const [companyIndicatorOptions, setCompanyIndicatorOptions] = useState([])
    const [industryIndicatorOptions, setIndustryIndicatorOptions] = useState([])

    // selected indicator of company and industry
    const [companyIndicatorSelect, setCompanyIndicatorSelect] = useState('')
    const [industryIndicatorSelect, setIndustryIndicatorSelect] = useState('')

    const [economicData, setEconomicData] = useState({
        economicYear: '',
        economicBudget: '',
        economicMonth: '',
    })

    const [clicked, setClicked] = useState('')
    const [go, setGo] = useState(false)
    // const [activeSuggestion, setActiveSuggestion] = useState(false)

    // Chart Options
    // const chartType = ['Bar Plot', 'Line Plot', 'Scatter Plot']
    const [chartOption, setChartOption] = useState('Bar Plot')

    useEffect(() => {
        const getInfo = async () => {
            // set options for economic inidicator
            const economicIndicatorOptns = []

            if (dash === 'Economic') {
                // Economic
                const eco_indicator = await axios.get(`${api}/economy/eco/indicator`)
                const all_eco = await eco_indicator.data
                await all_eco.map((e) => economicIndicatorOptns.push({ label: e, value: e }))
                setEconomicIndicatorOptions(economicIndicatorOptns)
            }

            // set options for company and inidicator
            const companyOptns = []
            const companyIndicatorOptns = []

            // set options for industry and inidciator
            const industryOptns = []
            const industryIndicatorOptns = []

            if (dash === 'Company') {
                // fetch company
                const fetch_company = await axios.get(`${api}/business/bus/company`)
                const company = await fetch_company.data

                // set company
                await company.map((e) => companyOptns.push({ label: e, value: e }))
                setCompanyOptions(companyOptns)

                if (companySelect) {
                    // Economic
                    const company_indicator = await axios.get(`${api}/business/bus/indicator/${companySelect.value}`)
                    const all_company_indicator = await company_indicator.data
                    await all_company_indicator.map((e) => companyIndicatorOptns.push({ label: e, value: e }))
                    setCompanyIndicatorOptions(companyIndicatorOptns)
                }
            }

            if (dash === 'Industry') {
                // fetch industry sector
                const fetch_industry = await axios.get(`${api}/industry/ind/sector`)
                const industry = await fetch_industry.data

                // set industry sector
                await industry.map((e) => industryOptns.push({ label: e, value: e }))
                setIndustryOptions(industryOptns)

                if (industrySelect) {
                    // Economic
                    const industry_indicator = await axios.get(`${api}/industry/ind/indicator/${industrySelect.value}`)
                    const all_industry_indicator = await industry_indicator.data
                    await all_industry_indicator.map((e) => industryIndicatorOptns.push({ label: e, value: e }))
                    setIndustryIndicatorOptions(industryIndicatorOptns)
                }
            }
        }
        getInfo()
    }, [companySelect, industrySelect, dash])

    useEffect(() => {
        // Handle search for Company dashboard
        const handleSubmitCompany = async () => {
            if (companySelect && companyIndicatorSelect.length !== 0) {
                const value1 = companySelect.value
                const value2 = companyIndicatorSelect.value
                const urlEconomicYear = `${api}/business/bus/year/${value1}/${value2}`
                const urlEconomicBudget = `${api}/business/bus/budget/${value1}/${value2}`
                const urlEconomicMonth = `${api}/business/bus/month/${value1}/${value2}`

                const chartDataYear = await axios.get(urlEconomicYear, {
                    headers: {
                        'Access-Control-Allow-Origin': frontEnd,
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                })
                const ecoYear = await chartDataYear.data
                ecoYear &&
                    setEconomicData((prev) => {
                        return { ...prev, economicYear: ecoYear }
                    })

                const chartDataBudget = await axios.get(urlEconomicBudget, {
                    headers: {
                        'Access-Control-Allow-Origin': frontEnd,
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                })
                const ecoBudget = await chartDataBudget.data
                ecoBudget &&
                    setEconomicData((prev) => {
                        return { ...prev, economicBudget: ecoBudget }
                    })

                const chartDataMonth = await axios.get(urlEconomicMonth, {
                    headers: {
                        'Access-Control-Allow-Origin': frontEnd,
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                })
                const ecoMonth = await chartDataMonth.data
                ecoMonth &&
                    setEconomicData((prev) => {
                        return { ...prev, economicMonth: ecoMonth }
                    })

                setGo(true)
            }
        }

        // Handle search for Industry dashboard
        const handleSubmitIndustry = async (e) => {
            if (industrySelect && industryIndicatorSelect.length !== 0) {
                const value1 = industrySelect.value
                const value2 = industryIndicatorSelect.value
                const urlEconomicYear = `${api}/industry/ind/year/${value1}/${value2}`
                const urlEconomicBudget = `${api}/industry/ind/budget/${value1}/${value2}`
                const urlEconomicMonth = `${api}/industry/ind/month/${value1}/${value2}`

                const chartDataYear = await axios.get(urlEconomicYear, {
                    headers: {
                        'Access-Control-Allow-Origin': frontEnd,
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                })
                const ecoYear = await chartDataYear.data
                ecoYear &&
                    setEconomicData((prev) => {
                        return { ...prev, economicYear: ecoYear }
                    })

                const chartDataBudget = await axios.get(urlEconomicBudget, {
                    headers: {
                        'Access-Control-Allow-Origin': frontEnd,
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                })
                const ecoBudget = await chartDataBudget.data
                ecoBudget &&
                    setEconomicData((prev) => {
                        return { ...prev, economicBudget: ecoBudget }
                    })

                const chartDataMonth = await axios.get(urlEconomicMonth, {
                    headers: {
                        'Access-Control-Allow-Origin': frontEnd,
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                })
                const ecoMonth = await chartDataMonth.data
                ecoMonth &&
                    setEconomicData((prev) => {
                        return { ...prev, economicMonth: ecoMonth }
                    })

                setGo(true)
            }
        }

        // Handle search for economic dashboard
        const handleSubmitEconomic = async () => {
            if (economicIndicatorSelect.length !== 0) {
                const urlEconomicYear = `${api}/economy/eco/year/${economicIndicatorSelect}`
                const urlEconomicBudget = `${api}/economy/eco/budget/${economicIndicatorSelect}`
                const urlEconomicMonth = `${api}/economy/eco/month/${economicIndicatorSelect}`

                const chartDataYear = await axios.get(urlEconomicYear, {
                    headers: {
                        'Access-Control-Allow-Origin': frontEnd,
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                })
                const ecoYear = await chartDataYear.data
                ecoYear &&
                    setEconomicData((prev) => {
                        return { ...prev, economicYear: ecoYear }
                    })

                const chartDataBudget = await axios.get(urlEconomicBudget, {
                    headers: {
                        'Access-Control-Allow-Origin': frontEnd,
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                })
                const ecoBudget = await chartDataBudget.data
                ecoBudget &&
                    setEconomicData((prev) => {
                        return { ...prev, economicBudget: ecoBudget }
                    })

                const chartDataMonth = await axios.get(urlEconomicMonth, {
                    headers: {
                        'Access-Control-Allow-Origin': frontEnd,
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                })
                const ecoMonth = await chartDataMonth.data
                ecoMonth &&
                    setEconomicData((prev) => {
                        return { ...prev, economicMonth: ecoMonth }
                    })
                setGo(true)
            }
        }

        dash === 'Company' && handleSubmitCompany()
        dash === 'Industry' && handleSubmitIndustry()
        dash === 'Economic' && handleSubmitEconomic()
    }, [economicIndicatorSelect, companyIndicatorSelect, dash, companySelect, industryIndicatorSelect, industrySelect])

    const generateData = async (chartData) => {
        // Budget Yearly
        if (chartData[0]['Budget Year']) {
            const dataValueY = []
            const dataValueX = []

            for (const i of chartData) {
                dataValueY.push(i['Budget Value'])
                dataValueX.push(i['Budget Year'])
            }
            setDataValueY(dataValueY)
            setDataValueX(dataValueX)
            setLegendY(chartData[0]['Source'])
            setLegendX(chartData[0]['Unit'])
            setDataForExport(chartData)
        }
        // Monthly
        else if (
            chartData[0]['Jan'] ||
            chartData[0]['Feb'] ||
            chartData[0]['Mar'] ||
            chartData[0]['Apr'] ||
            chartData[0]['May'] ||
            chartData[0]['Jun'] ||
            chartData[0]['Jul'] ||
            chartData[0]['Aug'] ||
            chartData[0]['Sep'] ||
            chartData[0]['Oct'] ||
            chartData[0]['Nov'] ||
            chartData[0]['Dec']
        ) {
            const dataValueY1 = []
            const dataValueY2 = []
            const dataValueY3 = []
            const dataValueY4 = []
            const dataValueY5 = []
            const dataValueY6 = []
            const dataValueY7 = []
            const dataValueY8 = []
            const dataValueY9 = []
            const dataValueY10 = []
            const dataValueY11 = []
            const dataValueY12 = []
            const dataValueMonthX = []
            for (const i of chartData) {
                dataValueY1.push(i.Jan)
                dataValueY2.push(i.Feb)
                dataValueY3.push(i.Mar)
                dataValueY4.push(i.Apr)
                dataValueY5.push(i.May)
                dataValueY6.push(i.Jun)
                dataValueY7.push(i.Jul)
                dataValueY8.push(i.Aug)
                dataValueY9.push(i.Sep)
                dataValueY10.push(i.Oct)
                dataValueY11.push(i.Nov)
                dataValueY12.push(i.Dec)

                dataValueMonthX.push(dash === 'Company' ? i['Year'] : i['Calendar Year'])
            }
            setDataValueMonthY({
                jan: dataValueY1,
                feb: dataValueY2,
                mar: dataValueY3,
                apr: dataValueY4,
                may: dataValueY5,
                jun: dataValueY6,
                jul: dataValueY7,
                aug: dataValueY8,
                sep: dataValueY9,
                oct: dataValueY10,
                nov: dataValueY11,
                dec: dataValueY12,
            })
            setDataValueMonthX(dataValueMonthX)
            setLegendY(chartData[0]['Source'])
            setLegendX(chartData[0]['Unit'])

            setDataForExport(chartData)
        }
        // Yearly
        else {
            const dataValueY = []
            const dataValueX = []

            for (const i of chartData) {
                dataValueY.push(i['Calendar Value'])
                dataValueX.push(dash === 'Company' ? i['Year'] : i['Calendar Year'])
            }
            setDataValueY(dataValueY)
            setDataValueX(dataValueX)
            setLegendY(chartData[0]['Source'])
            setLegendX(chartData[0]['Unit'])
            setDataForExport(chartData)
        }
    }

    const generateCondition = async () => {
        // pass any data to chart on first in
        if (economicData.economicMonth.Data && economicData.economicMonth.Data[0]) {
            setClicked('Monthly')
            await generateData(economicData.economicMonth.Data)
        } else if (economicData.economicYear.Data && economicData.economicYear.Data[0]) {
            setClicked('Yearly')
            await generateData(economicData.economicYear.Data)
        } else if (economicData.economicBudget.Data && economicData.economicBudget.Data[0]) {
            setClicked('Budget Yearly')
            await generateData(economicData.economicBudget.Data)
        }
    }
    if (go) {
        generateCondition()
        setGo(false)
    }

    return (
        <>
            <div className={classes.AllDashboards}>
                <div>
                    {dash === 'Company' && (
                        <>
                            {/* <div>
                                <p>
                                    {dash} Dashboard {'   '}{' '}
                                </p>
                            </div> */}

                            <div className={classes.form}>
                                <Overview
                                    dash={dash}
                                    p1="Company"
                                    p2="Data Series"
                                    p3="Data Point"
                                    v1="500+"
                                    v2="95,000+"
                                    v3="98,000+"
                                />

                                <div>
                                    <div className={classes.select}>
                                        <SelectData
                                            options={companyOptions}
                                            value={companySelect}
                                            selectOnChange={(e) => {
                                                if (e) {
                                                    setCompanySelect(e)
                                                    setCompanyIndicatorSelect('')
                                                } else {
                                                    setCompanySelect('')
                                                    setCompanyIndicatorSelect('')
                                                }
                                            }}
                                            search_by="Search company"
                                        />
                                    </div>
                                    <div className={classes.selectIndicator}>
                                        <SelectData
                                            options={companyIndicatorOptions}
                                            value={companyIndicatorSelect}
                                            selectOnChange={(e) =>
                                                e ? setCompanyIndicatorSelect(e) : setCompanyIndicatorSelect('')
                                            }
                                            search_by="Search indicator"
                                        />
                                    </div>

                                    <p id={classes.inputExample}> Ex: Dividend Payout</p>
                                </div>
                                <div>
                                    <p id={classes.timeline}>Timeline</p>

                                    <button
                                        type="button"
                                        disabled={
                                            economicData.economicMonth.Data && economicData.economicMonth.Data[0]
                                                ? false
                                                : true
                                        }
                                        className={clicked === 'Monthly' ? classes.btnActive : ''}
                                        onClick={() => {
                                            setClicked('Monthly')
                                            generateData(economicData.economicMonth.Data)
                                        }}>
                                        Monthly{' '}
                                        {!(economicData.economicMonth.Data && economicData.economicMonth.Data[0]) && (
                                            <div className={classes.timelineHoverMessage}>Not Applicable</div>
                                        )}
                                    </button>
                                    <button
                                        type="button"
                                        disabled={
                                            economicData.economicYear.Data && economicData.economicYear.Data[0]
                                                ? false
                                                : true
                                        }
                                        className={clicked === 'Yearly' ? classes.btnActive : ''}
                                        onClick={() => {
                                            setClicked('Yearly')
                                            generateData(economicData.economicYear.Data)
                                        }}>
                                        Yearly{' '}
                                        {!(economicData.economicYear.Data && economicData.economicYear.Data[0]) && (
                                            <div className={classes.timelineHoverMessage}>Not Applicable</div>
                                        )}
                                    </button>
                                    <button
                                        type="button"
                                        disabled={
                                            economicData.economicBudget.Data && economicData.economicBudget.Data[0]
                                                ? false
                                                : true
                                        }
                                        className={clicked === 'Budget Yearly' ? classes.btnActive : ''}
                                        onClick={() => {
                                            setClicked('Budget Yearly')
                                            generateData(economicData.economicBudget.Data)
                                        }}>
                                        Budget Yearly{' '}
                                        {!(economicData.economicBudget.Data && economicData.economicBudget.Data[0]) && (
                                            <div className={classes.timelineHoverMessage}>Not Applicable</div>
                                        )}
                                    </button>

                                    {/* <select
                                        className={classes.selectDropdown}
                                        value={chartOption}
                                        onChange={(e) => setChartOption(e.target.value)}>
                                        {chartType.map((x, i) => (
                                            <option className={classes.options} key={i} value={x}>
                                                {x}
                                            </option>
                                        ))}
                                    </select> */}
                                </div>
                            </div>
                        </>
                    )}

                    {dash === 'Industry' && (
                        <>
                            {/* <div>
                                <p>
                                    {dash} Dashboard {'   '}{' '}
                                </p>
                            </div> */}

                            <div className={classes.form}>
                                <Overview
                                    dash={dash}
                                    p1="Sector"
                                    p2="Data Series"
                                    p3="Data Point"
                                    v1="50+"
                                    v2="15,000+"
                                    v3="25,000+"
                                />

                                <div>
                                    <div className={classes.select}>
                                        <SelectData
                                            options={industryOptions}
                                            value={industrySelect}
                                            selectOnChange={(e) => {
                                                if (e) {
                                                    setIndustrySelect(e)
                                                    setIndustryIndicatorSelect('')
                                                } else {
                                                    setIndustrySelect('')
                                                    setIndustryIndicatorSelect('')
                                                }
                                            }}
                                            search_by="Search sector"
                                        />
                                    </div>
                                    <div className={classes.selectIndicator}>
                                        <SelectData
                                            options={industryIndicatorOptions}
                                            value={industryIndicatorSelect}
                                            selectOnChange={(e) =>
                                                e ? setIndustryIndicatorSelect(e) : setIndustryIndicatorSelect('')
                                            }
                                            search_by="Search indicator"
                                        />
                                    </div>

                                    <p id={classes.inputExample}> Ex: Mobile Internet User</p>
                                </div>
                                <div>
                                    <p id={classes.timeline}>Timeline</p>

                                    <button
                                        type="button"
                                        disabled={
                                            economicData.economicMonth.Data && economicData.economicMonth.Data[0]
                                                ? false
                                                : true
                                        }
                                        className={clicked === 'Monthly' ? classes.btnActive : ''}
                                        onClick={() => {
                                            setClicked('Monthly')
                                            generateData(economicData.economicMonth.Data)
                                        }}>
                                        Monthly{' '}
                                        {!(economicData.economicMonth.Data && economicData.economicMonth.Data[0]) && (
                                            <div className={classes.timelineHoverMessage}>Not Applicable</div>
                                        )}
                                    </button>
                                    <button
                                        type="button"
                                        disabled={
                                            economicData.economicYear.Data && economicData.economicYear.Data[0]
                                                ? false
                                                : true
                                        }
                                        className={clicked === 'Yearly' ? classes.btnActive : ''}
                                        onClick={() => {
                                            setClicked('Yearly')
                                            generateData(economicData.economicYear.Data)
                                        }}>
                                        Yearly{' '}
                                        {!(economicData.economicYear.Data && economicData.economicYear.Data[0]) && (
                                            <div className={classes.timelineHoverMessage}>Not Applicable</div>
                                        )}
                                    </button>
                                    <button
                                        type="button"
                                        disabled={
                                            economicData.economicBudget.Data && economicData.economicBudget.Data[0]
                                                ? false
                                                : true
                                        }
                                        className={clicked === 'Budget Yearly' ? classes.btnActive : ''}
                                        onClick={() => {
                                            setClicked('Budget Yearly')
                                            generateData(economicData.economicBudget.Data)
                                        }}>
                                        Budget Yearly{' '}
                                        {!(economicData.economicBudget.Data && economicData.economicBudget.Data[0]) && (
                                            <div className={classes.timelineHoverMessage}>Not Applicable</div>
                                        )}
                                    </button>

                                    {/* <select
                                        className={classes.selectDropdown}
                                        value={chartOption}
                                        onChange={(e) => setChartOption(e.target.value)}>
                                        {chartType.map((x, i) => (
                                            <option className={classes.options} key={i} value={x}>
                                                {x}
                                            </option>
                                        ))}
                                    </select> */}
                                </div>
                            </div>
                        </>
                    )}

                    {dash === 'Economic' && (
                        <>
                            {/* <div>
                                <p>
                                    {dash} Dashboard {'   '}{' '}
                                </p>
                            </div> */}

                            <div className={classes.form}>
                                <Overview
                                    dash={dash}
                                    p1="Indicator"
                                    p2="Data Series"
                                    p3="Data Point"
                                    v1="1000+"
                                    v2="12,000+"
                                    v3="23,000+"
                                />

                                <div>
                                    <div className={classes.selectIndicator}>
                                        <SelectData
                                            search_by="Search indicator"
                                            options={economicIndicatorOptions}
                                            selectOnChange={(e) =>
                                                e ? setEconomicIndicatorSelect(e.value) : setEconomicIndicatorSelect('')
                                            }
                                        />
                                    </div>
                                    <p id={classes.inputExample}> Ex: Tax Revenue</p>
                                </div>
                                <div>
                                    <p id={classes.timeline}>Timeline</p>

                                    <button
                                        type="button"
                                        disabled={
                                            economicData.economicMonth.Data && economicData.economicMonth.Data[0]
                                                ? false
                                                : true
                                        }
                                        className={clicked === 'Monthly' ? classes.btnActive : ''}
                                        onClick={() => {
                                            setClicked('Monthly')
                                            generateData(economicData.economicMonth.Data)
                                        }}>
                                        Monthly{' '}
                                        {!(economicData.economicMonth.Data && economicData.economicMonth.Data[0]) && (
                                            <div className={classes.timelineHoverMessage}>Not Applicable</div>
                                        )}
                                    </button>
                                    <button
                                        type="button"
                                        disabled={
                                            economicData.economicYear.Data && economicData.economicYear.Data[0]
                                                ? false
                                                : true
                                        }
                                        className={clicked === 'Yearly' ? classes.btnActive : ''}
                                        onClick={() => {
                                            setClicked('Yearly')
                                            generateData(economicData.economicYear.Data)
                                        }}>
                                        Yearly{' '}
                                        {!(economicData.economicYear.Data && economicData.economicYear.Data[0]) && (
                                            <div className={classes.timelineHoverMessage}>Not Applicable</div>
                                        )}
                                    </button>
                                    <button
                                        type="button"
                                        disabled={
                                            economicData.economicBudget.Data && economicData.economicBudget.Data[0]
                                                ? false
                                                : true
                                        }
                                        className={clicked === 'Budget Yearly' ? classes.btnActive : ''}
                                        onClick={() => {
                                            setClicked('Budget Yearly')
                                            generateData(economicData.economicBudget.Data)
                                        }}>
                                        Budget Yearly{' '}
                                        {!(economicData.economicBudget.Data && economicData.economicBudget.Data[0]) && (
                                            <div className={classes.timelineHoverMessage}>Not Applicable</div>
                                        )}
                                    </button>

                                    {/* <select
                                        className={classes.selectDropdown}
                                        value={chartOption}
                                        onChange={(e) => setChartOption(e.target.value)}>
                                        {chartType.map((x, i) => (
                                            <option className={classes.options} key={i} value={x}>
                                                {x}
                                            </option>
                                        ))}
                                    </select> */}
                                </div>
                            </div>
                        </>
                    )}
                </div>

                {chartOption === 'Bar Plot' && (
                    <div>
                        {clicked === 'Monthly' ? (
                            <BarChartMonthly
                                dataForExport={dataForExport}
                                dash={dash}
                                domainSearch={
                                    dash === 'Company'
                                        ? companySelect.value
                                        : dash === 'Industry'
                                        ? industrySelect.value
                                        : ''
                                }
                                search={
                                    dash === 'Company'
                                        ? companyIndicatorSelect.value
                                        : dash === 'Industry'
                                        ? industryIndicatorSelect.value
                                        : dash === 'Economic'
                                        ? economicIndicatorSelect
                                        : ''
                                }
                                jan={dataValueMonthY.jan}
                                feb={dataValueMonthY.feb}
                                mar={dataValueMonthY.mar}
                                apr={dataValueMonthY.apr}
                                may={dataValueMonthY.may}
                                jun={dataValueMonthY.jun}
                                jul={dataValueMonthY.jul}
                                aug={dataValueMonthY.aug}
                                sep={dataValueMonthY.sep}
                                oct={dataValueMonthY.oct}
                                nov={dataValueMonthY.nov}
                                dec={dataValueMonthY.dec}
                                dataValueMonthX={dataValueMonthX}
                                legendY={legendY}
                                legendX={legendX}
                                timeline={clicked}
                                downloadCount={props.downloadCount}
                                downloadLimit={props.downloadLimit}
                                handleUpdateDownload={props.handleUpdateDownload}
                                setCheck={props.setCheck}
                            />
                        ) : clicked === 'Yearly' ? (
                            <BarChart
                                dataForExport={dataForExport}
                                dash={dash}
                                domainSearch={
                                    dash === 'Company'
                                        ? companySelect.value
                                        : dash === 'Industry'
                                        ? industrySelect.value
                                        : ''
                                }
                                search={
                                    dash === 'Company'
                                        ? companyIndicatorSelect.value
                                        : dash === 'Industry'
                                        ? industryIndicatorSelect.value
                                        : dash === 'Economic'
                                        ? economicIndicatorSelect
                                        : ''
                                }
                                dataValueY={dataValueY}
                                dataValueX={dataValueX}
                                legendY={legendY}
                                legendX={legendX}
                                timeline={clicked}
                                downloadCount={props.downloadCount}
                                downloadLimit={props.downloadLimit}
                                handleUpdateDownload={props.handleUpdateDownload}
                                setCheck={props.setCheck}
                            />
                        ) : (
                            <BarChartBudget
                                dataForExport={dataForExport}
                                dash={dash}
                                domainSearch={
                                    dash === 'Company'
                                        ? companySelect.value
                                        : dash === 'Industry'
                                        ? industrySelect.value
                                        : ''
                                }
                                search={
                                    dash === 'Company'
                                        ? companyIndicatorSelect.value
                                        : dash === 'Industry'
                                        ? industryIndicatorSelect.value
                                        : dash === 'Economic'
                                        ? economicIndicatorSelect
                                        : ''
                                }
                                dataValueY={dataValueY}
                                dataValueX={dataValueX}
                                legendY={legendY}
                                legendX={legendX}
                                timeline={clicked}
                                downloadCount={props.downloadCount}
                                downloadLimit={props.downloadLimit}
                                handleUpdateDownload={props.handleUpdateDownload}
                                setCheck={props.setCheck}
                            />
                        )}
                    </div>
                )}

                {chartOption === 'Line Plot' && (
                    <div>
                        {clicked === 'Monthly' ? (
                            <LineChartMonthly
                                dataForExport={dataForExport}
                                dash={dash}
                                search={
                                    dash === 'Company'
                                        ? companyIndicatorSelect
                                        : dash === 'Industry'
                                        ? industryIndicatorSelect
                                        : dash === 'Economic'
                                        ? economicIndicatorSelect
                                        : ''
                                }
                                jan={dataValueMonthY.jan}
                                feb={dataValueMonthY.feb}
                                mar={dataValueMonthY.mar}
                                apr={dataValueMonthY.apr}
                                may={dataValueMonthY.may}
                                jun={dataValueMonthY.jun}
                                jul={dataValueMonthY.jul}
                                aug={dataValueMonthY.aug}
                                sep={dataValueMonthY.sep}
                                oct={dataValueMonthY.oct}
                                nov={dataValueMonthY.nov}
                                dec={dataValueMonthY.dec}
                                dataValueMonthX={dataValueMonthX}
                                legendY={legendY}
                                legendX={legendX}
                                timeline={clicked}
                            />
                        ) : (
                            <LineChart
                                dataForExport={dataForExport}
                                dash={dash}
                                search={
                                    dash === 'Company'
                                        ? companyIndicatorSelect
                                        : dash === 'Industry'
                                        ? industryIndicatorSelect
                                        : dash === 'Economic'
                                        ? economicIndicatorSelect
                                        : ''
                                }
                                dataValueY={dataValueY}
                                dataValueX={dataValueX}
                                legendY={legendY}
                                legendX={legendX}
                                timeline={clicked}
                            />
                        )}
                    </div>
                )}

                {chartOption === 'Scatter Plot' && (
                    <div>
                        {clicked === 'Monthly' ? (
                            <ScatterChartMonthly
                                dataForExport={dataForExport}
                                dash={dash}
                                search={
                                    dash === 'Company'
                                        ? companyIndicatorSelect
                                        : dash === 'Industry'
                                        ? industryIndicatorSelect
                                        : dash === 'Economic'
                                        ? economicIndicatorSelect
                                        : ''
                                }
                                jan={dataValueMonthY.jan}
                                feb={dataValueMonthY.feb}
                                mar={dataValueMonthY.mar}
                                apr={dataValueMonthY.apr}
                                may={dataValueMonthY.may}
                                jun={dataValueMonthY.jun}
                                jul={dataValueMonthY.jul}
                                aug={dataValueMonthY.aug}
                                sep={dataValueMonthY.sep}
                                oct={dataValueMonthY.oct}
                                nov={dataValueMonthY.nov}
                                dec={dataValueMonthY.dec}
                                dataValueMonthX={dataValueMonthX}
                                legendY={legendY}
                                legendX={legendX}
                                timeline={clicked}
                            />
                        ) : (
                            <ScatterChart
                                dataForExport={dataForExport}
                                dash={dash}
                                search={
                                    dash === 'Company'
                                        ? companyIndicatorSelect
                                        : dash === 'Industry'
                                        ? industryIndicatorSelect
                                        : dash === 'Economic'
                                        ? economicIndicatorSelect
                                        : ''
                                }
                                dataValueY={dataValueY}
                                dataValueX={dataValueX}
                                legendY={legendY}
                                legendX={legendX}
                                timeline={clicked}
                            />
                        )}
                    </div>
                )}

                {/* <p id={classes.NeedData}>
                    Need customize data? <button type="button">Talk to us</button>
                </p> */}
            </div>
        </>
    )
}
