import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Colors } from 'chart.js'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useCallback } from 'react'
import { Bar } from 'react-chartjs-2'
import { CSVLink } from 'react-csv'
import { useNavigate } from 'react-router'
import { useReactToPrint } from 'react-to-print'
import downloadCsvIcon from '../../../assets/downloadIcon/csv.png'
import downloadImageIcon from '../../../assets/downloadIcon/graph.png'
import downloadImageIconPdf from '../../../assets/downloadIcon/pdf.png'
import { UserInfo } from '../../../contexts/allContext'
import MultiRange from '../../MultiRange/MultiRange'
import classes from './BarChart.module.css'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Colors)
// ChartJS.options.title.text = ChartJS.options.title.text.toUpperCase()

export default function BarChartMonthly(props) {
    const navigate = useNavigate()
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info

    const [validationAlert, setAlert] = useState(false)

    const handlePopUpMessage = () => {
        setAlert(true)
        setTimeout(function () {
            setAlert(false)
        }, 3000)
    }

    const [validationAlertStandard, setAlertStandard] = useState(false)

    const handlePopUpMessageStandard = () => {
        setAlertStandard(true)
        setTimeout(function () {
            setAlertStandard(false)
        }, 3000)
    }

    const ref = useRef(null)
    const downloadImage = useCallback(() => {
        const link = document.createElement('a')
        link.download = 'chart.png'
        link.href = ref.current.toBase64Image()
        link.click()
    }, [])

    const pdfRef = useRef(null)
    const downloadImagePdf = useReactToPrint({
        content: () => pdfRef.current,
        documentTitle: 'graph',
        // onAfterPrint: () => alert('print success'),
    })

    const {
        dataForExport,
        domainSearch,
        dash,
        search,
        jan,
        feb,
        mar,
        apr,
        may,
        jun,
        jul,
        aug,
        sep,
        oct,
        nov,
        dec,
        dataValueMonthX,
        legendY,
        legendX,
        timeline,
    } = props

    const [xRange, setXRange] = useState(dataValueMonthX)
    const [yRange, setYRange] = useState({
        jan: jan,
        feb: feb,
        mar: mar,
        apr: apr,
        may: may,
        jun: jun,
        jul: jul,
        aug: aug,
        sep: sep,
        oct: oct,
        nov: nov,
        dec: dec,
    })

    const [minValue, set_minValue] = useState(0)
    const [maxValue, set_maxValue] = useState(100)
    useEffect(() => {
        const updateChartMulti = async () => {
            const rangeValueX = await dataValueMonthX.slice(minValue, maxValue)
            setXRange(rangeValueX)
            const jan = await props.jan.slice(minValue, maxValue)
            const feb = await props.feb.slice(minValue, maxValue)
            const mar = await props.mar.slice(minValue, maxValue)
            const apr = await props.apr.slice(minValue, maxValue)
            const may = await props.may.slice(minValue, maxValue)
            const jun = await props.jun.slice(minValue, maxValue)
            const jul = await props.jul.slice(minValue, maxValue)
            const aug = await props.aug.slice(minValue, maxValue)
            const sep = await props.sep.slice(minValue, maxValue)
            const oct = await props.oct.slice(minValue, maxValue)
            const nov = await props.nov.slice(minValue, maxValue)
            const dec = await props.dec.slice(minValue, maxValue)
            setYRange({
                jan: jan,
                feb: feb,
                mar: mar,
                apr: apr,
                may: may,
                jun: jun,
                jul: jul,
                aug: aug,
                sep: sep,
                oct: oct,
                nov: nov,
                dec: dec,
            })
        }

        updateChartMulti()
    }, [
        dataValueMonthX,
        props.jan,
        props.feb,
        props.mar,
        props.apr,
        props.may,
        props.jun,
        props.jul,
        props.aug,
        props.sep,
        props.oct,
        props.nov,
        props.dec,
        minValue,
        maxValue,
    ])

    const handleInput = (e) => {
        set_minValue(e.minValue)
        set_maxValue(e.maxValue)
    }

    const labels = dataValueMonthX ? xRange : ''
    const data = {
        labels,
        datasets: [
            // {
            //     label: timeline ? timeline : 'No data found',
            //     data: dataValueY ? dataValueY : '',
            //     borderColor: '#3e95cd',
            //     backgroundColor: 'rgb(62,149,205)',
            //     borderWidth: 2,
            //     type: 'line',
            //     fill: false,
            // },
            {
                label: jan ? 'Jan' : 'No data found',
                data: jan ? yRange.jan : '',
                backgroundColor: ['#100b95'],
                borderColor: ['#100b95'],
            },
            {
                label: feb ? 'Feb' : 'No data found',
                data: feb ? yRange.feb : '',
                backgroundColor: ['#4169e1'],
                borderColor: ['#4169e1'],
            },
            {
                label: mar ? 'Mar' : 'No data found',
                data: mar ? yRange.mar : '',
                backgroundColor: ['#1e90ff'],
                borderColor: ['#1e90ff'],
            },
            {
                label: apr ? 'Apr' : 'No data found',
                data: apr ? yRange.apr : '',
                backgroundColor: ['#6495ed'],
                borderColor: ['#6495ed'],
            },
            {
                label: may ? 'May' : 'No data found',
                data: may ? yRange.may : '',
                backgroundColor: ['#2C74B3'],
                borderColor: ['#2C74B3'],
            },
            {
                label: jun ? 'Jun' : 'No data found',
                data: jun ? yRange.jun : '',
                backgroundColor: ['#87cefa'],
                borderColor: ['#87cefa'],
            },
            {
                label: jul ? 'Jul' : 'No data found',
                data: jul ? yRange.jul : '',
                backgroundColor: ['#b0c4de'],
                borderColor: ['#b0c4de'],
            },
            {
                label: aug ? 'Aug' : 'No data found',
                data: aug ? yRange.aug : '',
                backgroundColor: ['#5DA7DB'],
                borderColor: ['#5DA7DB'],
            },
            {
                label: sep ? 'Sep' : 'No data found',
                data: sep ? yRange.sep : '',
                backgroundColor: ['#a2add0'],
                borderColor: ['#a2add0'],
            },
            {
                label: oct ? 'Oct' : 'No data found',
                data: oct ? yRange.oct : '',
                backgroundColor: ['#71a6d2'],
                borderColor: ['#71a6d2'],
            },
            {
                label: nov ? 'Nov' : 'No data found',
                data: nov ? yRange.nov : '',
                backgroundColor: ['#0D4C92'],
                borderColor: ['#0D4C92'],
            },
            {
                label: dec ? 'Dec' : 'No data found',
                data: dec ? yRange.dec : '',
                backgroundColor: ['#9bc4e2'],
                borderColor: ['#9bc4e2'],
            },
        ],
    }

    const options = {
        indexAxis: 'x',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            colors: {
                enabled: false,
            },
            legend: {
                position: 'top',
                display: false,
            },
            title: {
                display: true,
                text: search && `${search} (${legendX})`,
                font: {
                    size: 18,
                },
            },
            backgroundColor: 'rgba(255, 99, 132)',
        },
        scales: {
            x: {
                grid: {
                    borderDash: [1, 20],
                    color: 'rgba(0, 0, 0, 0)',
                },
                title: {
                    display: true,
                    text: legendY ? `Source: ${legendY}` : '',
                    font: {
                        size: 13,
                    },
                },
            },
            y: {
                grid: {
                    color: 'white',
                },
                title: {
                    display: true,
                    text: legendX ? `Unit of Measurement: ${legendX}` : '',
                    font: {
                        size: 13,
                    },
                },
            },
        },
    }

    return (
        <>
            <div className={classes.chartWrapper} ref={pdfRef}>
                {/* <p>{search ? (option ? option : 'Please select timeline') : 'Please search by indicator first'}</p> */}
                <Bar ref={ref} data={data} options={options} />
            </div>
            <MultiRange
                dataValueX={dataValueMonthX}
                xRange={xRange}
                minValue={minValue}
                maxValue={maxValue}
                handleInput={handleInput}
            />
            <div className={classes.download}>
                <CSVLink
                    onClick={(e) => {
                        if (userInfo.restriction === 1) {
                            if (userInfo.role === 'standard_m' && props.downloadCount <= props.downloadLimit) {
                                props.handleUpdateDownload(props.downloadCount + 1)
                                props.setCheck(true)
                                return true
                            } else if (userInfo.role === 'standard_y' && props.downloadCount <= props.downloadLimit) {
                                props.handleUpdateDownload(props.downloadCount + 1)
                                props.setCheck(true)
                                return true
                            } else {
                                alert('Your download limit exceeded!')
                                return false
                            }
                        } else if (
                            (userInfo.role === 'admin' ||
                                userInfo.role === 'standard_y' ||
                                userInfo.role === 'standard_m') &&
                            userInfo.restriction === 0
                        ) {
                            handlePopUpMessageStandard()
                            return false
                        } else {
                            handlePopUpMessage()
                            return false
                        }
                    }}
                    className={classes.btnCsvExport}
                    data={
                        dataForExport
                            ? dash === 'Company'
                                ? dataForExport.map((e) => ({
                                      Company: domainSearch,
                                      Indicator: search,
                                      Year: e.Year,
                                      Jan: e.Jan,
                                      Feb: e.Feb,
                                      Mar: e.Mar,
                                      Apr: e.Apr,
                                      May: e.May,
                                      Jun: e.Jun,
                                      Jul: e.Jul,
                                      Aug: e.Aug,
                                      Sep: e.Sep,
                                      Oct: e.Oct,
                                      Nov: e.Nov,
                                      Dec: e.Dec,
                                      Unit: e.Unit,
                                      Source: e.Source,
                                  }))
                                : dash === 'Industry'
                                ? dataForExport.map((e) => ({
                                      Industry: domainSearch,
                                      Indicator: search,
                                      Year: e['Calendar Year'],
                                      Jan: e.Jan,
                                      Feb: e.Feb,
                                      Mar: e.Mar,
                                      Apr: e.Apr,
                                      May: e.May,
                                      Jun: e.Jun,
                                      Jul: e.Jul,
                                      Aug: e.Aug,
                                      Sep: e.Sep,
                                      Oct: e.Oct,
                                      Nov: e.Nov,
                                      Dec: e.Dec,
                                      Unit: e.Unit,
                                      Source: e.Source,
                                  }))
                                : dataForExport.map((e) => ({
                                      Indicator: search,
                                      Year: e['Calendar Year'],
                                      Jan: e.Jan,
                                      Feb: e.Feb,
                                      Mar: e.Mar,
                                      Apr: e.Apr,
                                      May: e.May,
                                      Jun: e.Jun,
                                      Jul: e.Jul,
                                      Aug: e.Aug,
                                      Sep: e.Sep,
                                      Oct: e.Oct,
                                      Nov: e.Nov,
                                      Dec: e.Dec,
                                      Unit: e.Unit,
                                      Source: e.Source,
                                  }))
                            : ''
                    }
                    filename={
                        dash === 'Company'
                            ? `${domainSearch}_${search}_${timeline}`
                            : dash === 'Industry'
                            ? `${domainSearch}_${search}_${timeline}`
                            : `${search}_${timeline}`
                    }>
                    <img src={downloadCsvIcon} alt="" />
                    <p>Export Data as csv</p>
                </CSVLink>
                {/* onClick={() => downloadImage()}
                onClick={() => downloadImagePdf()} */}
                <button
                    onClick={() => {
                        if (userInfo.restriction === 1) {
                            downloadImage()
                        } else if (
                            (userInfo.role === 'admin' ||
                                userInfo.role === 'standard_y' ||
                                userInfo.role === 'standard_m') &&
                            userInfo.restriction === 0
                        ) {
                            handlePopUpMessageStandard()
                        } else {
                            handlePopUpMessage()
                        }
                    }}>
                    <img src={downloadImageIcon} alt="" />
                    <p>Export Graph as png</p>
                </button>
                <button
                    onClick={() => {
                        if (userInfo.restriction === 1) {
                            downloadImagePdf()
                        } else if (
                            (userInfo.role === 'admin' ||
                                userInfo.role === 'standard_y' ||
                                userInfo.role === 'standard_m') &&
                            userInfo.restriction === 0
                        ) {
                            handlePopUpMessageStandard()
                        } else {
                            handlePopUpMessage()
                        }
                    }}>
                    <img src={downloadImageIconPdf} alt="" />
                    <p>Export Graph as pdf</p>
                </button>
                <div
                    style={{ display: validationAlert || validationAlertStandard ? '' : 'none' }}
                    className={classes.popUpMessage}>
                    {validationAlert && <p>Please upgrade to standard account to avail this service</p>}
                    {validationAlertStandard && (
                        <p onClick={() => navigate('/payment-procedure')}>
                            Please complete the payment and wait for your payment confirmation email.
                            <br></br>Click for the payment method.
                        </p>
                    )}
                </div>
            </div>
        </>
    )
}
