import classes from './Faq.module.css'

export const faqData = {
    // title: "FAQ (How it works)",
    rows: [
        {
            title: 'What is Data Terminal?',
            content: (
                <p id="FAQ1">
                    Data Terminal is a single platform for all Company data, industry data & economic data, with
                    numerous data points and indicators from different industries and companies.
                </p>
            ),
        },
        {
            title: 'How can I find data on the Data Terminal?',
            content: (
                <p>
                    Once you have signed in to Data Terminal you will be able to log in to the data portal. In the Data
                    Terminal, there are specific segments of dashboards where you will be able to find your required
                    data by selecting the sectors, company, and indicators.
                </p>
            ),
        },
        {
            title: 'How can I subscribe to Data Terminal?',
            content: (
                <p>
                    Once you click to sign up, you will be able to fill in your Name, Email Address, Company, and
                    Designation, and set up your Password.
                </p>
            ),
        },
        {
            title: 'What to do if I forget my password?',
            content: (
                <p>
                    If you forget your password you can email the Data Terminal Team at{' '}
                    <span id={classes.emailFaq}>data@bizdatainsights.com</span> and your password will be reset with an
                    email.
                </p>
            ),
        },
        {
            title: 'How to find the required data?',
            content: (
                <p>
                    Once you sign in to the Data Terminal, you will get different dashboards such as Company Dashboard,
                    Industry Dashboard, and Economic Dashboard.
                </p>
            ),
        },
        {
            title: 'How can I find the Economic data?',
            content: (
                <p>
                    You can get the economic data at the Economic Dashboard in the Data Terminal. In the economic
                    dashboard, you have to select the indicator and relevant timeline for getting economic data.
                    Example- Economic Dashboard, Indicator = Per Capita GDP.
                </p>
            ),
        },
        {
            title: 'How can I find the Business data?',
            content: (
                <p>
                    You can get the Business data at the Company Dashboard in the Data Terminal. In the Company
                    dashboard, first, you have to select the sector, then select the required company, indicator &
                    required timeline for getting business data. Example- Company Dashboard, Sector = Bank, Company=
                    Brac Bank, Indicator= Number of Branches.
                </p>
            ),
        },
        {
            title: 'How can I find the Industry data?',
            content: (
                <p>
                    You can get the Industry data at the industry Dashboard in the Data Terminal. In the industry
                    dashboard, first, you have to select the sector, then select the required indicator required
                    timeline for getting industry data. Example: Industry Dashboard, Sector = RMG & Textile, Indicator=
                    Knitwear Export, Timeline= Budget Yearly.
                </p>
            ),
        },
        {
            title: 'How can I find monthly data?',
            content: (
                <p>
                    Once you set your required sector and indicator in the specific dashboard, you will be able to
                    choose monthly data in the timeline.
                </p>
            ),
        },
        {
            title: 'How can I find Budget Yearly data?',
            content: (
                <p>
                    Once you set your required sector and indicator in the specific dashboard, you will be able to
                    choose budget yearly data in the timeline.
                </p>
            ),
        },
        {
            title: 'How can I find Yearly data?',
            content: (
                <p>
                    Once you set your required sector and indicator in the specific dashboard, you will be able to
                    choose yearly data in the timeline.
                </p>
            ),
        },
        {
            title: 'What is the authenticity of the data?',
            content: (
                <p>
                    In the Data Terminal, you will be able to find the sources of particular data given at the bottom of
                    the data series. For instance, Source: Bangladesh Bank.
                </p>
            ),
        },
        {
            title: 'Where can I find the unit of measurement?',
            content: (
                <p>
                    To find the Unit of Measurement you have to look on the y axis of the graph. You will find the unit
                    of measurement there.
                </p>
            ),
        },
    ],
}

export const faqStyles = {
    bgColor: 'white',
    // titleTextColor: "black",
    rowTitleColor: 'black',
    rowContentPaddingTop: '2.5rem',
    rowContentPaddingBottom: '0.7rem',
    // rowContentColor: 'grey',
    arrowColor: 'grey',
    transitionDuration: '0.5s',
    rowTitleTextSize: '1rem',
    rowContentTextSize: '0.9rem',
}

export const faqConfig = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true,
}

export const faqTitle = [
    'What is Data Terminal?',
    'How can I find data on the Data Terminal?',
    'How can I subscribe to Data Terminal?',
    'What to do if I forget my password?',
    'How to find the required data?',
    'How can I find the Economic data?',
    'How can I find the Business data?',
    'How can I find the Industry data?',
    'How can I find monthly data?',
    'How can I find Budget Yearly data?',
    'How can I find Yearly data?',
    'What is the authenticity of the data?',
    'Where can I find the unit of measurement?',
]
