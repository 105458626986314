import React from 'react'
import Footer from '../components/Footer/Footer'
import Layout from '../components/Layout/Layout'
import TermsConditions from '../components/TermsConditions/TermsConditions'

export default function TermsConditionsPage() {
    return (
        <Layout>
            <TermsConditions />
            <Footer />
        </Layout>
    )
}
