import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import eyeHide from '../../../assets/showhide/eyeHide.png'
import eyeOpen from '../../../assets/showhide/eyeOpen.png'
import { SubscriptionPlan } from '../../../variables/SubscriptionPlan'
import { countryList } from '../../../variables/countryList'
import PopUp from '../../PopUp/PopUp'
import LoadingBar from '../../Resource/LoadingBar/LoadingBar'
import { SelectData } from '../../SelectData/SelectData'
import classes from './Register.module.css'

const api = process.env.REACT_APP_BASE_URL
const frontEnd = process.env.REACT_APP_CLIENT_URL

export default function Register() {
    const [activePopUp, setActivePopUp] = useState(false)
    const { role } = useParams()

    const navigate = useNavigate()
    const [clicked, setClicked] = useState(false)
    const [clickedConfirm, setClickedConfirm] = useState(false)
    const [loading, setLoading] = useState(false)

    const [alert, setAlert] = useState(false)

    const [signUpMsg, setSignUpMsg] = useState(false)

    // const dateToday = moment().format('DD MMMM YYYY')
    const dateMonthly = moment().add(30, 'days').format('DD MMMM YYYY')
    const dateYearly = moment().add(1, 'year').format('DD MMMM YYYY')

    const [userInfo, setUserInfo] = useState({
        name: '',
        email: '',
        company: '',
        designation: '',
        phone: '',
        country: '',
        password: '',
        confirm_password: '',
        role: role,
        restriction: 0,
        download: 0,
        downloadLimit: 0,
        subscriptionPlan: '',
    })

    useEffect(() => {
        if (role !== 'standard_user' && role !== 'individual_user') {
            navigate('/register/individual_user')
        }
    }, [role, navigate])

    const handleRegister = async (e) => {
        e.preventDefault()
        setLoading(true)
        if (userInfo.password === userInfo.confirm_password) {
            const signUp = await axios.post(
                `${api}/signup/user/signup`,
                {
                    name: userInfo.name,
                    email: userInfo.email,
                    company: userInfo.company,
                    designation: userInfo.designation,
                    phone_number: userInfo.phone,
                    country: userInfo.country,
                    role: role === 'standard_user' ? userInfo.subscriptionPlan : 'individual_user',
                    password: userInfo.password,
                    restriction: 0,
                    download: userInfo.download,
                    download_limit:
                        userInfo.subscriptionPlan === 'standard_m'
                            ? 20
                            : userInfo.subscriptionPlan === 'standard_y'
                            ? 600
                            : 0,
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': frontEnd,
                    },
                    withCredentials: true,
                }
            )
            if (signUp && signUp.data.Message === 'User registration complete') {
                if (role === 'standard_user') {
                    setLoading(false)
                    setActivePopUp(true)
                } else if (role !== 'standard_user') {
                    setLoading(false)
                    navigate('/login')
                }
            } else if (signUp && signUp.data.Message === 'User already registered') {
                setLoading(false)
                setSignUpMsg('You are already registered! Please go for sign in.')
                setTimeout(function () {
                    setSignUpMsg('')
                }, 5000)
            } else {
                setSignUpMsg('Signup failed! Please try again after sometime.')
                setTimeout(function () {
                    setSignUpMsg('')
                }, 5000)
                setLoading(false)
            }
            setLoading(false)
        } else {
            setSignUpMsg('Password mismatch!!')
            setTimeout(function () {
                setSignUpMsg('')
            }, 5000)
            setLoading(false)
        }
    }

    return (
        <>
            <PopUp
                btnClickPopUp={'2'}
                activePopUp={activePopUp}
                setActivePopUp={setActivePopUp}
                payment={
                    userInfo.subscriptionPlan === 'standard_m'
                        ? 299
                        : userInfo.subscriptionPlan === 'standard_y'
                        ? 999
                        : ''
                }
            />
            {/* <button onClick={() => setActivePopUp(true)}>button</button> */}
            <div className={classes.login}>
                <div>
                    <p
                        onClick={() => {
                            navigate('/')
                        }}>
                        {' '}
                        D A T A &nbsp;&nbsp; T E R M I N A L
                    </p>
                </div>

                <div>
                    <button
                        onClick={() => {
                            navigate('/')
                        }}>
                        ← Go to Homepage
                    </button>
                    <div>
                        <p>Sign Up</p>
                        {signUpMsg && <p id={classes.redAlert}>⚠ {signUpMsg}</p>}
                        {userInfo.confirm_password && userInfo.confirm_password !== userInfo.password && (
                            <p id={classes.redAlert}>⚠ The password mismatch!</p>
                        )}
                        {/* <p id={classes.pleaseEnter}>Please enter your credentials to login</p> */}
                        <form id={classes.loginForm} onSubmit={(e) => handleRegister(e)}>
                            <div>
                                <div>
                                    <p>Name</p>
                                    <input
                                        type="name"
                                        placeholder="Enter your name"
                                        name="name"
                                        value={userInfo.name}
                                        onChange={(e) => {
                                            e
                                                ? setUserInfo((prev) => {
                                                      return { ...prev, name: e.target.value }
                                                  })
                                                : setUserInfo((prev) => {
                                                      return { ...prev, name: NaN }
                                                  })
                                        }}
                                        required
                                    />
                                    <p>Email address</p>
                                    <input
                                        type="email"
                                        placeholder="Enter your email"
                                        name="email"
                                        value={userInfo.email}
                                        onChange={(e) => {
                                            e
                                                ? setUserInfo((prev) => {
                                                      return { ...prev, email: e.target.value }
                                                  })
                                                : setUserInfo((prev) => {
                                                      return { ...prev, email: NaN }
                                                  })
                                        }}
                                        required
                                    />
                                    <p>Company name</p>
                                    <input
                                        type="company"
                                        placeholder="Company name"
                                        name="company"
                                        value={userInfo.company}
                                        onChange={(e) => {
                                            e
                                                ? setUserInfo((prev) => {
                                                      return { ...prev, company: e.target.value }
                                                  })
                                                : setUserInfo((prev) => {
                                                      return { ...prev, company: NaN }
                                                  })
                                        }}
                                        required
                                    />
                                    <div className={classes.switchDiv1}>
                                        <p>Designation</p>
                                        <input
                                            type="designation"
                                            placeholder="Designation"
                                            name="designation"
                                            value={userInfo.designation}
                                            onChange={(e) => {
                                                e
                                                    ? setUserInfo((prev) => {
                                                          return { ...prev, designation: e.target.value }
                                                      })
                                                    : setUserInfo((prev) => {
                                                          return { ...prev, designation: NaN }
                                                      })
                                            }}
                                            required
                                        />
                                    </div>
                                    <div className={classes.switchDiv2}>
                                        <p>Password</p>
                                        <div>
                                            <input
                                                type={clicked ? 'text' : 'password'}
                                                placeholder="Password"
                                                name="password"
                                                value={userInfo.password}
                                                onChange={(e) => {
                                                    e
                                                        ? setUserInfo((prev) => {
                                                              return { ...prev, password: e.target.value }
                                                          })
                                                        : setUserInfo((prev) => {
                                                              return { ...prev, password: NaN }
                                                          })
                                                }}
                                                required=""
                                            />
                                            {userInfo.password && (
                                                <button
                                                    className={classes.btn1}
                                                    type="button"
                                                    onClick={() => setClicked(!clicked)}>
                                                    <img src={clicked ? eyeOpen : eyeHide} alt="" />
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    {role === 'standard_user' && (
                                        <>
                                            <p>
                                                Subscription plan standard <br></br> (Monthly / Yearly)
                                            </p>
                                            <div className={classes.selectDataLayout}>
                                                <SelectData
                                                    search_by={'Select Your Plan'}
                                                    options={SubscriptionPlan}
                                                    selectOnChange={(e) =>
                                                        e
                                                            ? setUserInfo((prev) => {
                                                                  return { ...prev, subscriptionPlan: e.value }
                                                              })
                                                            : setUserInfo((prev) => {
                                                                  return { ...prev, subscriptionPlan: NaN }
                                                              })
                                                    }
                                                />
                                                <input
                                                    style={{ opacity: 0, height: 0, position: 'absolute', bottom: 0 }}
                                                    value={userInfo.subscriptionPlan ? userInfo.subscriptionPlan : ''}
                                                    onChange={(e) => e.target.value}
                                                    required
                                                />
                                            </div>
                                            <p id={classes.subscriptionPlanTime}>
                                                {userInfo.subscriptionPlan === 'standard_m'
                                                    ? `Your subscription time will over on ${dateMonthly}`
                                                    : userInfo.subscriptionPlan === 'standard_y'
                                                    ? `Your subscription time will over on ${dateYearly}`
                                                    : ''}
                                            </p>
                                        </>
                                    )}
                                </div>

                                <div>
                                    <p className={classes.country}>Country</p>
                                    <div className={classes.selectDataLayout}>
                                        <SelectData
                                            search_by={'Select Country'}
                                            options={countryList}
                                            selectOnChange={(e) =>
                                                e
                                                    ? setUserInfo((prev) => {
                                                          return { ...prev, country: e.value }
                                                      })
                                                    : setUserInfo((prev) => {
                                                          return { ...prev, country: NaN }
                                                      })
                                            }
                                        />
                                        <input
                                            style={{ opacity: 0, height: 0, position: 'absolute', top: 25 }}
                                            value={userInfo.country ? userInfo.country : ''}
                                            onChange={(e) => e.target.value}
                                            required
                                        />
                                    </div>
                                    <p>Phone number</p>
                                    <input
                                        type="tel"
                                        placeholder="Phone number"
                                        name="phone"
                                        value={userInfo.phone}
                                        onChange={(e) => {
                                            e
                                                ? setUserInfo((prev) => {
                                                      return { ...prev, phone: e.target.value }
                                                  })
                                                : setUserInfo((prev) => {
                                                      return { ...prev, phone: NaN }
                                                  })
                                        }}
                                    />
                                    <div className={classes.switchDiv1}>
                                        <p>Password</p>
                                        <div>
                                            <input
                                                type={clicked ? 'text' : 'password'}
                                                placeholder="Password"
                                                name="password"
                                                value={userInfo.password}
                                                onChange={(e) => {
                                                    e
                                                        ? setUserInfo((prev) => {
                                                              return { ...prev, password: e.target.value }
                                                          })
                                                        : setUserInfo((prev) => {
                                                              return { ...prev, password: NaN }
                                                          })
                                                }}
                                                required=""
                                            />
                                            {userInfo.password && (
                                                <button
                                                    className={classes.btn1}
                                                    type="button"
                                                    onClick={() => setClicked(!clicked)}>
                                                    <img src={clicked ? eyeOpen : eyeHide} alt="" />
                                                </button>
                                            )}
                                        </div>
                                    </div>

                                    <div className={classes.switchDiv2}>
                                        <p>Designation</p>
                                        <input
                                            type="designation"
                                            placeholder="Designation"
                                            name="designation"
                                            value={userInfo.designation}
                                            onChange={(e) => {
                                                e
                                                    ? setUserInfo((prev) => {
                                                          return { ...prev, designation: e.target.value }
                                                      })
                                                    : setUserInfo((prev) => {
                                                          return { ...prev, designation: NaN }
                                                      })
                                            }}
                                            required
                                        />
                                    </div>

                                    <p>Confirm password</p>
                                    <div>
                                        <input
                                            type={clickedConfirm ? 'text' : 'password'}
                                            placeholder="Confirm password"
                                            name="confirm_password"
                                            value={userInfo.confirm_password}
                                            onChange={(e) => {
                                                e
                                                    ? setUserInfo((prev) => {
                                                          return { ...prev, confirm_password: e.target.value }
                                                      })
                                                    : setUserInfo((prev) => {
                                                          return { ...prev, confirm_password: NaN }
                                                      })
                                            }}
                                            required=""
                                        />
                                        {userInfo.confirm_password && (
                                            <button
                                                className={classes.btn2}
                                                type="button"
                                                onClick={() => setClickedConfirm(!clickedConfirm)}>
                                                <img src={clickedConfirm ? eyeOpen : eyeHide} alt="" />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {loading ? (
                                <div id={classes.loading}>
                                    <LoadingBar height={15} color={'blue'} />
                                </div>
                            ) : (
                                <button type="submit">Sign Up</button>
                            )}
                        </form>
                        <p className={classes.message}>
                            Already registered? <a href="/login">Sign In</a>
                        </p>
                    </div>
                    <div>
                        For assistance, pls mail to us{' '}
                        <span style={{ fontWeight: 'bold' }}>data@bizdatainsights.com</span>
                    </div>
                </div>
            </div>
        </>
    )
}
