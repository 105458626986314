import React, { useRef, Component } from 'react'
import Slider from 'react-slick'
import classes from './SliderComponent.module.css'

export default function SliderComponent(props) {
    const { d1, d2, d3, d4, d5, d6, d7, d8 } = props
    const data = [d1, d2, d3, d4, d5, d6, d7, d8]
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 6000,
        autoplaySpeed: 6000,
        cssEase: 'linear',
    }

    return (
        <>
            <div className={classes.slider}>
                <Slider {...settings}>
                    {data.map((e, index) => {
                        return (
                            <div key={index} className={classes.sliderDiv}>
                                <p>{e}</p>
                            </div>
                        )
                    })}
                </Slider>
            </div>
        </>
    )
}
