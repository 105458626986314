import React from 'react'
import Footer from '../components/Footer/Footer'
import Layout from '../components/Layout/Layout'
import PaymentProcess from '../components/PaymentProcess/PaymentProcess'

export default function PaymentProcessPage() {
    return (
        <Layout>
            <PaymentProcess />
            <Footer />
        </Layout>
    )
}
