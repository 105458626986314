import React from 'react'
import { Table } from '../../Resource'
import classes from './SearchTable.module.css'

export default function SearchTable(props) {
    return (
        <Table scroll={true}>
            <thead>
                <tr>
                    <th className={`${classes.stickyColumn} ${classes.firstColumn}`}>#</th>
                    <th className={`${classes.stickyColumn} ${classes.secondColumn}`}>Company</th>
                    <th>Industry</th>
                    <th>Sub Industry</th>
                    <th>Company Type</th>
                    <th>Company Origin</th>
                    <th>Country Origin</th>
                    <th>City/Division</th>
                    <th>Year of Origin</th>
                    <th>Year of Inception of Bangladesh operation</th>
                    <th>Product/Service Description</th>
                    <th>Major Brands</th>
                    <th>Company Description</th>
                    <th>Founder</th>
                    <th>Manpower</th>
                    <th>Disributorship/Business Partner</th>
                    <th>DSC Listing Year</th>
                    <th>CSE Listing Year</th>
                    <th>Authorized Capital (Cr. BDT)</th>
                    <th>Paid Up Capital</th>
                    <th>Number of Securities (Million)</th>
                    <th>Partner/Investor 1</th>
                    <th>Shareholding (%)</th>
                    <th>Partner/Investor 2</th>
                    <th>Shareholding (%)</th>
                    <th>Partner/Investor 3</th>
                    <th>Shareholding (%)</th>
                    <th>Partner/Investor 4</th>
                    <th>Shareholding (%)</th>
                    <th>Partner/Investor 5</th>
                    <th>Shareholding (%)</th>
                    <th>HR Person 1</th>
                    <th>HR Person 1 designation</th>
                    <th>HR Person 1 official email</th>
                    <th>HR Person 1 personal email</th>
                    <th>HR Person 1 official number</th>
                    <th>HR Person 1 personal number</th>
                    <th>HR Person 2</th>
                    <th>HR Person 2 designation</th>
                    <th>HR Person 2 official email</th>
                    <th>HR Person 2 personal email</th>
                    <th>HR Person 2 official number</th>
                    <th>HR Person 2 personal number</th>
                    <th>HR Person 3</th>
                    <th>HR Person 3 designation</th>
                    <th>HR Person 3 official email</th>
                    <th>HR Person 3 personal email</th>
                    <th>HR Person 3 official number</th>
                    <th>HR Person 3 personal number</th>
                    <th>HR Person 4</th>
                    <th>HR Person 4 designation</th>
                    <th>HR Person 4 official email</th>
                    <th>HR Person 4 personal email</th>
                    <th>HR Person 4 official number</th>
                    <th>HR Person 4 personal number</th>
                    <th>HR Person 5</th>
                    <th>HR Person 5 designation</th>
                    <th>HR Person 5 official email</th>
                    <th>HR Person 5 personal email</th>
                    <th>HR Person 5 official number</th>
                    <th>HR Person 5 personal number</th>

                    <th>Address Corporate Office</th>
                    <th>Address Main Factory Production</th>
                    <th>Email</th>
                    <th>Contact Details</th>
                    <th>Website</th>
                    <th>Other Info</th>
                    <th>Reference1</th>
                    <th>Reference2</th>
                    <th>Reference3</th>
                </tr>
            </thead>
            <tbody>
                {props.data &&
                    props.data.map((data, index) => (
                        <tr key={index}>
                            <td className={`${classes.stickyColumn} ${classes.firstColumn}`}>{index + props.slNo}</td>
                            <td
                                id={classes.companyNameTd}
                                className={`${classes.stickyColumn} ${classes.secondColumn}`}
                                // onClick={(e) => {
                                //     props.setPopUp(true)
                                //     props.setCompanyInfo(data)
                                // }}
                            >
                                {data.company}
                            </td>
                            <td>{data.industry}</td>
                            <td>{data.subindustry}</td>
                            <td>
                                <p
                                    id={classes.companyType}
                                    className={
                                        data.company_type === 'Private'
                                            ? classes.private
                                            : data.company_type === 'Public'
                                            ? classes.public
                                            : ''
                                    }>
                                    {data.company_type}
                                </p>
                            </td>
                            <td>{data.company_origin}</td>
                            <td>{data.country_of_origin}</td>
                            <td>{data.city_or_division}</td>
                            <td>{data.year_of_origin}</td>
                            <td id={classes.companyDetails}>{data.year_of_inception_of_bangladesh_operation}</td>
                            <td id={classes.companyDetails} className={classes.desc}>
                                {data.product_or_service_description}
                            </td>
                            <td id={classes.companyDetails}>{data.major_brands}</td>
                            <td id={classes.companyDetails} className={classes.desc}>
                                {data.company_description}
                            </td>
                            <td id={classes.companyDetails}>{data.founder}</td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.manpower}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.disributorship_or_business_partner}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.dse_listing_year}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.cse_listing_year}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.authorised_capital_cr_in_bdt}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.paid_up_capital_cr_in_bdt}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.number_of_securities_in_million}
                            </td>
                            {/* <td>
            <li>{data.partner_investor_1}</li>
            <li>Shareholding: {data.partner_investor_1_shareholding}%</li>
        </td> */}
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.partner_investor_1}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.partner_investor_1_shareholding}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.partner_investor_2}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.partner_investor_2_shareholding}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.partner_investor_3}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.partner_investor_3_shareholding}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.partner_investor_4}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.partner_investor_4_shareholding}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.partner_investor_5}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.partner_investor_5_shareholding}
                            </td>

                            {/* <td>
            <li>Name: {data.key_person_1}</li>
            <li>Designation: {data.key_person_1_designation}</li>
            <li>Official Email: {data.key_person_1_official_email}</li>
            <li>Personal Email: {data.key_person_1_personal_email}</li>
            <li>Official Contact: {data.key_person_1_official_number}</li>
            <li>Personal Contact: {data.key_person_1_personal_number}</li>
        </td> */}

                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_1}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_1_designation}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_1_official_email}
                                {/* <span id={classes.email1}>
                    {data.key_person_1_official_email && 'awefazd'}
                </span>
                {data.key_person_1_official_email && '@'}
                <span id={classes.email2}>
                    {data.key_person_1_official_email.split('@')[1]}
                </span> */}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_1_personal_email}
                                {/* <span id={classes.email1}>
                    {data.key_person_1_personal_email && 'awefazd'}
                </span>
                {data.key_person_1_personal_email && '@'}
                <span id={classes.email2}>
                    {data.key_person_1_personal_email.split('@')[1]}
                </span> */}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {/* <span id={classes.email2}>
                    {data.key_person_1_official_number.split('-')[0]}
                </span>
                {data.key_person_1_official_number && '-'}
                <span id={classes.email1}>
                    {data.key_person_1_official_number && '01523'}
                </span> */}
                                {data.key_person_1_official_number}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_1_personal_number}
                                {/* <span id={classes.email2}>
                    {data.key_person_1_personal_number.split('-')[0]}
                </span>
                {data.key_person_1_personal_number && '-'}
                <span id={classes.email1}>
                    {data.key_person_1_personal_number && '01523'}
                </span> */}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_2}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_2_designation}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_2_official_email}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_2_personal_email}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_2_official_number}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_2_personal_number}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_3}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_3_designation}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_3_official_email}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_3_personal_email}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_3_official_number}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_3_personal_number}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_4}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_4_designation}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_4_official_email}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_4_personal_email}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_4_official_number}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_4_personal_number}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_5}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_5_designation}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_5_official_email}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_5_personal_email}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_5_official_number}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.key_person_5_personal_number}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.address_corporate_office}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.address_main_factory_production}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {/* {data.email_address.split(',', 3).map((data, i) => {<>{data[0].split('@')[i]}@{data[i].split('@')[1]}</>})} */}
                                {/* {data.email_address.split(',', 3)[0].split('@')[0]}@
                {data.email_address.split(',', 3)[0].split('@')[1]} */}
                                {data.email_address}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.contact_number}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.website}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.other_info}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.references_1}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.references_2}
                            </td>
                            <td id={classes.companyDetails} onClick={(e) => props.showDataDetails(data.company)}>
                                {data.references_3}
                            </td>
                        </tr>
                    ))}
            </tbody>
        </Table>
    )
}
