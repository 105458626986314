import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import eyeHide from '../../../assets/showhide/eyeHide.png'
import eyeOpen from '../../../assets/showhide/eyeOpen.png'
import { Auth } from '../../../contexts/allContext'
import { api, frontEnd } from '../../../utils/apiToken'
import classes from './SignUpPopUp.module.css'

export default function SignUpPopUp(props) {
    const { activePopUp, setActivePopUp } = props

    const { stateAuth, dispatchAuth } = useContext(Auth)
    const [validationAlert, setAlert] = useState(false)

    const navigate = useNavigate()
    const [clicked, setClicked] = useState(false)
    const [userInfo, setUserInfo] = useState({
        email: '',
        password: '',
    })

    if (activePopUp) {
        document.body.classList.add(classes.activeModel)
    } else {
        document.body.classList.remove(classes.activeModel)
    }

    // if (stateAuth.auth) {
    //     setActivePopUp(false)
    // }

    useEffect(() => {
        if (stateAuth.auth) {
            setActivePopUp(false)
            navigate('/dashboard')
        }
    }, [navigate, stateAuth, activePopUp, setActivePopUp])

    const handleLogin = async (e) => {
        e.preventDefault()

        let logFetch = await axios.post(
            `${api}/login/login`,
            { email: userInfo.email, password: userInfo.password },
            {
                headers: {
                    'Access-Control-Allow-Origin': frontEnd,
                },
                withCredentials: true,
            }
        )

        let log = await logFetch.data

        if (log.Token) {
            dispatchAuth({ type: 'token', payload: log.Token })
        } else {
            setAlert(true)
            setTimeout(function () {
                setAlert(false)
            }, 3000)
        }
    }

    const handleSignUp = (role) => {
        navigate(`/register/${role}`)
    }

    return (
        <>
            <div className={classes.loginPopUp}>
                <button className={classes.close} onClick={() => setActivePopUp(false)}>
                    X
                </button>
                <p className={classes.p1}>Sign In to get data</p>
                <p className={classes.p2}>Please enter your credentials to login</p>
                {validationAlert && <p className={classes.alertMessage}>Invalid Username or Password!</p>}
                <form id={classes.loginForm} onSubmit={(e) => handleLogin(e)}>
                    <p className={classes.pass}>Email address</p>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        name="email"
                        value={userInfo.email}
                        onChange={(e) => {
                            e
                                ? setUserInfo((prev) => {
                                      return { ...prev, email: e.target.value }
                                  })
                                : setUserInfo((prev) => {
                                      return { ...prev, email: NaN }
                                  })
                        }}
                        required
                    />
                    <p className={classes.pass}>Password</p>
                    <input
                        type={clicked ? 'text' : 'password'}
                        placeholder="Enter your password"
                        name="password"
                        value={userInfo.password}
                        onChange={(e) => {
                            e
                                ? setUserInfo((prev) => {
                                      return { ...prev, password: e.target.value }
                                  })
                                : setUserInfo((prev) => {
                                      return { ...prev, password: NaN }
                                  })
                        }}
                        required
                    />
                    {userInfo.password && (
                        <button className={classes.eyeBtn} type="button" onClick={() => setClicked(!clicked)}>
                            <img src={clicked ? eyeOpen : eyeHide} alt="" />
                        </button>
                    )}
                    <button className={classes.btnLogin} type="submit">
                        Sign In
                    </button>
                </form>
                <p className={classes.message}>
                    Not registered?
                    <button type="button" onClick={(e) => handleSignUp('individual_user')}>
                        Sign Up
                    </button>
                </p>
            </div>
        </>
    )
}
