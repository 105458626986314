import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import PopUp from '../../PopUp/PopUp'
import classes from './Login.module.css'
import LoginContainer from './LoginContainer/LoginContainer'

export default function Login() {
    const navigate = useNavigate()
    const [activePopUp, setActivePopUp] = useState(false)
    const [role, setRole] = useState('')

    return (
        <>
            <PopUp
                btnClickPopUp={'5'}
                activePopUp={activePopUp}
                setActivePopUp={setActivePopUp}
                payment={role === 'standard_m' ? 299 : role === 'standard_y' ? 999 : ''}
            />
            <div className={classes.login}>
                <div>
                    <p
                        onClick={() => {
                            navigate('/')
                        }}>
                        {' '}
                        D A T A &nbsp;&nbsp; T E R M I N A L
                    </p>
                </div>

                <div>
                    <button
                        onClick={() => {
                            navigate('/')
                        }}>
                        ← Go to Homepage
                    </button>
                    <LoginContainer setRole={setRole} setActivePopUp={setActivePopUp} />
                </div>
            </div>
        </>
    )
}
