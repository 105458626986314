import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import c6 from '../../assets/CompanyLogo/BAT_Bangladesh_Logo.png'
import c2 from '../../assets/CompanyLogo/Grameenphone-Logo.png'
import c3 from '../../assets/CompanyLogo/Marico_Logo.svg.png'
import c1 from '../../assets/CompanyLogo/Unilever.svg.png'
import c5 from '../../assets/CompanyLogo/bKash.png'
import c8 from '../../assets/CompanyLogo/brac_bank_logo.png'
import c7 from '../../assets/CompanyLogo/robi_logo.png'
import c4 from '../../assets/CompanyLogo/walton_logo.png'
import e3 from '../../assets/IndustryLogo/Account Balance Icon.png'
import i6 from '../../assets/IndustryLogo/Agriculture icon.png'
import e2 from '../../assets/IndustryLogo/Budget Icon.png'
import e4 from '../../assets/IndustryLogo/CPI Icon.png'
import i1 from '../../assets/IndustryLogo/Consumer & Retail icon.png'
import i2 from '../../assets/IndustryLogo/Electronics icon.png'
import i4 from '../../assets/IndustryLogo/Energy & Power icon.png'
import e7 from '../../assets/IndustryLogo/Export Icon.png'
import e5 from '../../assets/IndustryLogo/FDI Icon.png'
import e1 from '../../assets/IndustryLogo/GDP Icon.png'
import i7 from '../../assets/IndustryLogo/Healthcare & Pharma icon.png'
import i5 from '../../assets/IndustryLogo/Housing icon.png'
import e6 from '../../assets/IndustryLogo/Import Icon.png'
import i8 from '../../assets/IndustryLogo/RMG & Textile icon.png'
import e8 from '../../assets/IndustryLogo/Remittance Icon.png'
import i3 from '../../assets/IndustryLogo/Telecom icon.png'
import { api } from '../../utils/apiToken'
// import searchIcon from '../../assets/search.png'
// import CompanySlider from '../CompanySlider/CompanySlider'
import OverviewDashboard from '../Overview/OverviewDashboard/OverveiwDashboard'
import PopUp from '../PopUp/PopUp'
import { SelectData } from '../SelectData/SelectData'
import SliderComponent from '../Slider/SliderComponent'
import classes from './AboutDashboard.module.css'

export default function AboutDashboard(props) {
    const { dashboardTitle, dashboardParagraph, reverse, count } = props

    const [companyOptions, setCompanyOptions] = useState([])
    const [industryOptions, setIndustryOptions] = useState([])

    const [companySelect, setCompanySelect] = useState('')
    const [industrySelect, setIndustrySelect] = useState('')

    const [companyIndicator, setCompanyIndicator] = useState([])
    const [industryIndicator, setIndustryIndicator] = useState([])
    const [economicIndicator, setEconomicIndicator] = useState('')

    useEffect(() => {
        const getInfo = async () => {
            // set options for company
            const companyOptns = []

            // set options for industry
            const industryOptns = []

            // fetch company
            const fetch_company = await axios.get(`${api}/business/bus/company`)
            const company = await fetch_company.data

            // set company
            await company.map((e) => companyOptns.push({ label: e, value: e }))
            setCompanyOptions(companyOptns)

            if (companySelect) {
                // Economic
                const company_indicator = await axios.get(`${api}/business/bus/indicator/${companySelect}`)
                const all_company_indicator = await company_indicator.data
                setCompanyIndicator(all_company_indicator)
            }

            // fetch industry sector
            const fetch_industry = await axios.get(`${api}/industry/ind/sector`)
            const industry = await fetch_industry.data

            // set industry sector
            await industry.map((e) => industryOptns.push({ label: e, value: e }))
            setIndustryOptions(industryOptns)

            if (industrySelect) {
                // Economic
                const industry_indicator = await axios.get(`${api}/industry/ind/indicator/${industrySelect}`)
                const all_industry_indicator = await industry_indicator.data
                setIndustryIndicator(all_industry_indicator)
            }

            // Economic
            const eco_indicator = await axios.get(`${api}/economy/eco/indicator`)
            const all_eco = await eco_indicator.data
            setEconomicIndicator(all_eco)
        }
        getInfo()
    }, [companySelect, industrySelect])

    // console.log(economicIndicator)

    // if (companySelect) {
    //     document.querySelector('.indicatorList').classList.add(classes.expanded)
    // }
    const [activePopUp, setActivePopUp] = useState(false)
    const handleClickPopUp = (e, dataClicked) => {
        setActivePopUp(true)
    }

    return (
        <>
            <PopUp btnClickPopUp={'3'} activePopUp={activePopUp} setActivePopUp={setActivePopUp} />
            <div
                id={count === 0 ? 'Company' : count === 1 ? 'Industry' : count === 2 ? 'Economic' : ''}
                className={reverse ? classes.DashboardsReverse : classes.Dashboards}>
                <div>
                    <div>
                        <div>
                            {count === 0 && (
                                <>
                                    <p>Top Search Companies</p>
                                    <div>
                                        <div style={{ width: '15%' }}>
                                            <img style={{ width: '100%' }} src={c8} alt="" />
                                        </div>
                                        <div style={{ width: '15%' }}>
                                            <img style={{ width: '100%' }} src={c2} alt="" />
                                        </div>
                                        <div style={{ width: '8%' }}>
                                            <img style={{ width: '100%' }} src={c3} alt="" />
                                        </div>
                                        <div style={{ width: '10%' }}>
                                            <img style={{ width: '100%' }} src={c4} alt="" />
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ width: '10%' }}>
                                            <img style={{ width: '100%' }} src={c5} alt="" />
                                        </div>
                                        <div style={{ width: '13%' }}>
                                            <img style={{ width: '100%' }} src={c6} alt="" />
                                        </div>
                                        <div style={{ width: '8%' }}>
                                            <img style={{ width: '100%' }} src={c7} alt="" />
                                        </div>
                                        <div style={{ width: '6%' }}>
                                            <img style={{ width: '100%' }} src={c1} alt="" />
                                        </div>
                                    </div>
                                </>
                            )}
                            {count === 1 && (
                                <>
                                    <p>Top Search Industries</p>
                                    <div>
                                        <div>
                                            <img src={i1} alt="" />
                                            <p>Consumer and Retail</p>
                                        </div>
                                        <div>
                                            <img src={i2} alt="" />
                                            <p>Electronics</p>
                                        </div>
                                        <div>
                                            <img src={i3} alt="" />
                                            <p>Telecom</p>
                                        </div>
                                        <div>
                                            <img src={i4} alt="" />
                                            <p>Energy and Power</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <img src={i5} alt="" />
                                            <p>Housing</p>
                                        </div>
                                        <div>
                                            <img src={i6} alt="" />
                                            <p>Agriculture</p>
                                        </div>
                                        <div>
                                            <img src={i7} alt="" />
                                            <p>Healthcare and Pharma</p>
                                        </div>
                                        <div>
                                            <img src={i8} alt="" />
                                            <p>RMG and Textile</p>
                                        </div>
                                    </div>
                                </>
                            )}
                            {count === 2 && (
                                <>
                                    <p>Top Search Industries</p>
                                    <div>
                                        <div>
                                            <img src={e1} alt="" />
                                            <p>GDP</p>
                                        </div>
                                        <div>
                                            <img src={e2} alt="" />
                                            <p>Budget</p>
                                        </div>
                                        <div>
                                            <img src={e3} alt="" />
                                            <p>Account Balance</p>
                                        </div>
                                        <div>
                                            <img src={e4} alt="" />
                                            <p>CPI</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <img src={e5} alt="" />
                                            <p>FDI</p>
                                        </div>
                                        <div>
                                            <img src={e6} alt="" />
                                            <p>Import</p>
                                        </div>
                                        <div>
                                            <img src={e7} alt="" />
                                            <p>Export</p>
                                        </div>
                                        <div>
                                            <img src={e8} alt="" />
                                            <p>Remittance</p>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div>
                        <div>
                            {count === 0 && (
                                <>
                                    <p>Recently Added Companies</p>
                                    <div className={classes.sliderComponent}>
                                        <SliderComponent
                                            d1="aamra networks limited"
                                            d2="Intech Limited"
                                            d3="IT Consultants Limited"
                                            d4="Far Chemical Industries Limited"
                                            d5="Imam Button Industries Ltd"
                                            d6="JMI Hospital Requisite Manufacturing Limited"
                                            d7="Pharma Aids"
                                            d8="Silva Pharmaceuticals Limited"
                                        />
                                    </div>
                                </>
                            )}

                            {count === 1 && (
                                <>
                                    <p>Recently Updated Industry</p>
                                    <div className={classes.sliderComponent}>
                                        <SliderComponent
                                            d1="Telecom"
                                            d2="Bank"
                                            d3="Agriculture & Livestock"
                                            d4="Oil & Petroleum"
                                            d5="Migration"
                                            d6="Non-bank Financial Institution (NBFI)"
                                            d7="Stationary Paper & Printing"
                                            d8="Fintech"
                                        />
                                    </div>
                                </>
                            )}

                            {count === 2 && (
                                <>
                                    {/* Economy */}
                                    <p>Recently Updated Indicators</p>
                                    <div className={classes.sliderComponent}>
                                        <SliderComponent
                                            d1="Railway's Income"
                                            d2="Railway's Expenditure"
                                            d3="Bangladesh Government Islamic Investment Bond"
                                            d4="Literacy Rate - National"
                                            d5="Non- NBR Tax Revenue"
                                            d6="Life Expectancy at Birth for Male"
                                            d7="Average Per Capita Per Day Vegetables Intake - National"
                                            d8="GINI Index for Income Inequality - National"
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div>
                        {(count === 0 || count === 1) && (
                            <div>
                                {count === 0 && (
                                    <>
                                        <p>Recently Updated Companies</p>
                                        <div className={classes.sliderComponent}>
                                            <SliderComponent
                                                d1="Asia Pacific General Insurance Company Limited"
                                                d2="Prime Insurance"
                                                d3="Prime Islami Life Insurance"
                                                d4="Dutch Bangla Bank"
                                                d5="Eastern Bank Ltd."
                                                d6="Uttara Bank Limited"
                                                d7="Aramit Cement"
                                                d8="Bashundhara Cement"
                                            />
                                        </div>
                                    </>
                                )}
                                {count === 1 && (
                                    <>
                                        {/* Industry */}
                                        <p>Recently Updated Indicators</p>
                                        <div className={classes.sliderComponent}>
                                            <SliderComponent
                                                d1="Overseas Employment or Export of Manpower - Brunei"
                                                d2="Overseas Employment or Export of Manpower - Qatar"
                                                d3="Paper & Paper Products Export"
                                                d4="Printed Materials Export"
                                                d5="CSR Expense - Total"
                                                d6="CSR Expense - Education"
                                                d7="Marine Fish Production - Volume"
                                                d8="Pangas Fish Production - Volume"
                                            />
                                        </div>
                                    </>
                                )}

                                {/* {count === 2 && (
                                <>
                                    <p>Recently Added Companies</p>
                                    <div className={classes.sliderComponent}>
                                        <SliderComponent />
                                    </div>
                                </>
                            )} */}
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    <div>
                        <h1>{dashboardTitle}</h1>
                    </div>
                    <p>{dashboardParagraph}</p>

                    {count === 0 && (
                        <OverviewDashboard
                            p1="95,000+"
                            p2="500+"
                            p3="50+"
                            p4="600+"
                            pp1="Financial & Non-Financial Indicators"
                            pp2="Listed & Non-Listed Companies"
                            pp3="Business Secotors"
                            pp4="National & Int'l Statistical Souces"
                        />
                    )}
                    {count === 1 && (
                        <OverviewDashboard
                            p1="25,000+"
                            p2="15,000+"
                            p3="50+"
                            p4="60+"
                            pp1="Data Points"
                            pp2="Data series"
                            pp3="Sectors"
                            pp4="Sources"
                        />
                    )}
                    {count === 2 && (
                        <OverviewDashboard
                            p1="23,000+"
                            p2="12,000+"
                            p3="20+"
                            p4="50+"
                            pp1="Data Points"
                            pp2="Indicators"
                            pp3="Years of Data"
                            pp4="Sources"
                        />
                    )}

                    <div>
                        {/* <img src={searchIcon} alt="" /> */}
                        {count === 0 ? (
                            <>
                                <SelectData
                                    search_by={'Search indicator by company'}
                                    options={companyOptions}
                                    selectOnChange={(e) => {
                                        if (e) {
                                            setCompanySelect(e.value)
                                        } else {
                                            setCompanySelect('')
                                        }
                                    }}
                                />
                                {companySelect && <div>Indicator List of {companySelect}</div>}
                                <div className="indicatorList">
                                    {companySelect &&
                                        companyIndicator.map((e, index) => {
                                            return (
                                                <div onClick={() => handleClickPopUp(true)} key={index}>
                                                    {e}
                                                </div>
                                            )
                                        })}
                                </div>
                            </>
                        ) : count === 1 ? (
                            <>
                                <SelectData
                                    search_by={'Search indicator by sector'}
                                    options={industryOptions}
                                    selectOnChange={(e) => {
                                        if (e) {
                                            setIndustrySelect(e.value)
                                        } else {
                                            setIndustrySelect('')
                                        }
                                    }}
                                />
                                {industrySelect && <div>Indicator List of {industrySelect}</div>}

                                <div>
                                    {industrySelect &&
                                        industryIndicator.map((e, index) => {
                                            return (
                                                <div onClick={() => handleClickPopUp(true)} key={index}>
                                                    {e}
                                                </div>
                                            )
                                        })}
                                </div>
                            </>
                        ) : count === 2 ? (
                            <>
                                <div></div>
                                <div>Indicator List of Economic Dashboard</div>
                                <div>
                                    {economicIndicator &&
                                        economicIndicator.map((e, index) => {
                                            return (
                                                <div onClick={() => handleClickPopUp(true)} key={index}>
                                                    {e}
                                                </div>
                                            )
                                        })}
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>

            {/* <CompanySlider /> */}
        </>
    )
}
