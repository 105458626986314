import React from 'react'
import classes from './OverviewDashboard.module.css'

export default function OverviewDashboard(props) {
    const { p1, p2, p3, p4, pp1, pp2, pp3, pp4 } = props
    return (
        <>
            <div className={classes.OverviewDashboard}>
                <div>
                    <p>{p1}</p>
                    <p>{pp1}</p>
                </div>
                <div>
                    <p>{p2}</p>
                    <p>{pp2}</p>
                </div>
                <div>
                    <p>{p3}</p>
                    <p>{pp3}</p>
                </div>
                <div>
                    <p>{p4}</p>
                    <p>{pp4}</p>
                </div>
            </div>
        </>
    )
}
