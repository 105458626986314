import React from 'react'
import company from '../../assets/overview/o1.png'
import industry from '../../assets/overview/o2.png'
import economic from '../../assets/overview/o3.png'
import classes from './Overview.module.css'

export default function Overview() {
    return (
        <>
            <div className={classes.Overview}>
                <a href="#Company">
                    <div>
                        <img src={company} alt="" /> <p>Company Dashboard</p>
                    </div>
                    <p>Provides 95,000+ datasets for 500+ listed and non-listed companies' in Bangladesh.</p>
                </a>
                <a href="#Industry">
                    <div>
                        <img src={industry} alt="" /> <p>Industry Dashboard</p>
                    </div>
                    <p>Offers industry-level data of 15,000+ datasets across 50+ sectors in Bangladesh.</p>
                </a>
                <a href="#Economic">
                    <div>
                        <img src={economic} alt="" /> <p>Economic Dashboard</p>
                    </div>
                    <p>Offers 12,000+ economic datasets from 50+ sources on Bangladesh's economy.</p>
                </a>
            </div>
            {/* <div class={classes.pattern}>
                <div class={classes.geeks}>
                    <h1>GEEKS FOR GEEKS</h1>
                </div>
            </div> */}
        </>
    )
}
