import React from 'react'
import facebookIcon from '../../assets/socialIcon/facebook.png'
import linkedinIcon from '../../assets/socialIcon/linkedin.png'
import youtubeIcon from '../../assets/socialIcon/youtube.png'
import classes from './Footer.module.css'

export default function Footer() {
    return (
        <>
            <div className={classes.Footer}>
                <div>
                    <div>
                        <p>
                            <span>Data</span>
                            <span>Terminal</span>
                            <span>powered by BIZDATASIGHTS</span>
                        </p>
                        <p>First data portal in Bangladesh for Company Data, Industry Data & Economy Data</p>
                    </div>
                    <div>
                        <p>Menu</p>
                        <div>
                            <a href="#About">About</a>
                            <a href="#Economic">Company Dashboard</a>
                            <a href="#Industry">Industry Dashboard</a>
                            <a href="#Economic">Economic Dashboard</a>
                        </div>
                    </div>
                    <div>
                        <p>Follow Us</p>
                        <div>
                            <a href="https://www.facebook.com/BIZDATA.INSIGHTS/">
                                <img src={facebookIcon} alt="" />
                            </a>
                            <a href="https://www.linkedin.com/company/bizdatainsight/">
                                <img src={linkedinIcon} alt="" />
                            </a>
                            <a href="https://youtube.com/channel/UCdkc6uPeOKcyMx8z31g_wAw">
                                <img src={youtubeIcon} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
                <div>
                    <p>© 2022 Data Terminal. Powered by bizdatainsights.com</p>
                </div>
            </div>
        </>
    )
}
