import axios from 'axios'
import { useContext, useEffect } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import { Auth, UserInfo } from '../contexts/allContext'
import { api } from '../utils/apiToken'

export default function ProtectedRoute() {
    const { stateAuth, dispatchAuth } = useContext(Auth)
    const { dispatchUser } = useContext(UserInfo)

    // const api = process.env.REACT_APP_BASE_URL
    const frontEnd = process.env.REACT_APP_CLIENT_URL
    let token = stateAuth.token

    const navigate = useNavigate()

    useEffect(() => {
        let userFetch = async () => {
            let logFetch = await axios.get(`${api}/login/auth`, {
                headers: {
                    'Access-Control-Allow-Origin': frontEnd,
                    Authorization: `Bearer ${token}`,
                },
                withCredentials: true,
            })

            let log = await logFetch.data

            if (log.msg === 'found_token') {
                dispatchAuth({ type: 'token', payload: token })
                dispatchUser({ type: 'set', payload: log })
            } else {
                dispatchAuth({ type: 'remove' })
                dispatchUser({ type: 'remove' })
            }

            //role check
            // if (preventUser.indexOf(log.role) !== -1) {
            //     dispatchAuth({ type: 'remove' })
            //     dispatchUser({ type: 'remove' })
            //     navigate('/login')
            // }
        }

        try {
            userFetch()
        } catch (e) {
            dispatchAuth({ type: 'remove' })
            dispatchUser({ type: 'remove' })
        }
    }, [navigate, dispatchAuth, dispatchUser, token, frontEnd])
    //dispatchUser, api, token, dispatchAuth, preventUser

    return stateAuth.auth === true ? <Outlet /> : <Navigate to="/login" />
}
