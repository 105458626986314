import MultiRangeSlider from 'multi-range-slider-react'
import React from 'react'
import classes from './MultiRange.module.css'
import './MultiRangeSlider.css'

export default function MultiRange(props) {
    const { dataValueX, xRange, minValue, maxValue, handleInput } = props

    // const labelA = dataValueX[0]
    // const labelB = dataValueX.at(-1)
    return (
        <>
            <div className={classes.rangeSlider}>
                <p>Filter by Year</p>
                <MultiRangeSlider
                    min={0}
                    max={dataValueX.length}
                    step={1}
                    minValue={minValue}
                    maxValue={maxValue}
                    onInput={(e) => {
                        handleInput(e)
                    }}
                    baseClassName="multi-range-slider"
                    ruler={false}
                    label={false}
                    // labels={[labelA, labelB]}
                    minCaption={xRange[0]}
                    maxCaption={xRange.at(-1)}
                    // barInnerColor={'var(--home-color)'}
                />
            </div>
        </>
    )
}
