import React from 'react'
import AboutDashboard from '../AboutDashboard/AboutDashboard'
import {
    AboutCompanyDashboard,
    AboutEconomicDashboard,
    AboutIndustryDashboard,
} from '../AboutDashboard/AboutDashboardParagraph'
import ContactUs from '../ContactUs/ContactUs'
import Faq from '../Faq/Faq'
import Footer from '../Footer/Footer'
import HowItWorks from '../HowItWorks/HowItWorks'
import LandingView from '../LandingView/LandingView'
import Overview from '../Overview/Overview'
import PaymentProcess from '../PaymentProcess/PaymentProcess'
import Subscription from '../Subscription/Subscription'

export default function Home(props) {
    const { scrollToSubscription } = props
    const economicDashboardParagraph = <AboutEconomicDashboard />
    const industryDashboardParagraph = <AboutIndustryDashboard />
    const companyDashboardParagraph = <AboutCompanyDashboard />

    const dashboardTitle = ['Company Dashboard', 'Industry Dashboard', 'Economic Dashboard']
    const dashboardParagraph = [companyDashboardParagraph, industryDashboardParagraph, economicDashboardParagraph]
    const reverse = [false, true, false]

    return (
        <>
            <LandingView />

            <Overview />

            {dashboardTitle.map((e, i) => {
                return (
                    <div key={i}>
                        <AboutDashboard
                            dashboardTitle={dashboardTitle[i]}
                            dashboardParagraph={dashboardParagraph[i]}
                            reverse={reverse[i]}
                            count={i}
                        />
                    </div>
                )
            })}

            <HowItWorks />

            <Subscription scrollToSubscription={scrollToSubscription} />

            <PaymentProcess />

            <Faq />

            <ContactUs />

            <Footer />
        </>
    )
}
