import React from 'react'
import all_dashboard from '../../assets/howitworks/all_dashboard.png'
import com_company from '../../assets/howitworks/com_company.png'
import com_db from '../../assets/howitworks/com_db.png'
import com_indicator from '../../assets/howitworks/com_indicator.png'
import com_sector from '../../assets/howitworks/com_sector.png'
import com_timeline from '../../assets/howitworks/com_timeline.png'
import eco_db from '../../assets/howitworks/eco_db.png'
import eco_indicator from '../../assets/howitworks/eco_indicator.png'
import eco_timeline from '../../assets/howitworks/eco_timeline.png'
import ind_db from '../../assets/howitworks/ind_db.png'
import ind_indicator from '../../assets/howitworks/ind_indicator.png'
import ind_sector from '../../assets/howitworks/ind_sector.png'
import ind_timeline from '../../assets/howitworks/ind_timeline.png'
import signin from '../../assets/howitworks/signin.png'
import signup from '../../assets/howitworks/signup.png'
import signup_credential from '../../assets/howitworks/signup_credential.png'
import classes from './HowItWorks.module.css'

export default function HowItWorks() {
    return (
        <div id="HIW" className={classes.howItWorks}>
            <div>
                <div>How it Works</div>
                <div>
                    <div>
                        <img src={''} alt="" />
                    </div>
                    <div>
                        <img src={''} alt="" />
                    </div>
                    <div>
                        <img src={signup} alt="" />
                    </div>
                    <p>&#x27F6;</p>
                    <div>
                        <img src={signup_credential} alt="" />
                    </div>
                    <p>&#x27F6;</p>
                    <div>
                        <img src={all_dashboard} alt="" />
                    </div>
                    <div>
                        <img src={''} alt="" />
                    </div>
                    <div>
                        <img src={''} alt="" />
                    </div>
                </div>
            </div>
            <div>
                <div>User Guide for Economic Dashboard</div>
                <div>
                    <div>
                        <img src="" alt="" />
                    </div>
                    <div>
                        <img src={signin} alt="" />
                    </div>
                    <p>&#x27F6;</p>
                    <div>
                        <img src={eco_db} alt="" />
                    </div>
                    <p>&#x27F6;</p>
                    <div>
                        <img src={eco_indicator} alt="" />
                    </div>
                    <p>&#x27F6;</p>
                    <div>
                        <img src={eco_timeline} alt="" />
                    </div>
                    <div>
                        <img src="" alt="" />
                    </div>
                </div>
            </div>
            <div>
                <div>User Guide for Company Dashboard</div>
                <div>
                    <div>
                        <img src={signin} alt="" />
                    </div>
                    <p>&#x27F6;</p>
                    <div>
                        <img src={com_db} alt="" />
                    </div>
                    <p>&#x27F6;</p>
                    <div>
                        <img src={com_sector} alt="" />
                    </div>
                    <p>&#x27F6;</p>
                    <div>
                        <img src={com_company} alt="" />
                    </div>
                    <p>&#x27F6;</p>
                    <div>
                        <img src={com_indicator} alt="" />
                    </div>
                    <p>&#x27F6;</p>
                    <div>
                        <img src={com_timeline} alt="" />
                    </div>
                </div>
            </div>
            <div>
                <div>User Guide for Industry Dashboard</div>
                <div>
                    <div>
                        <img src={''} alt="" />
                    </div>
                    <div>
                        <img src={signin} alt="" />
                    </div>
                    <p>&#x27F6;</p>
                    <div>
                        <img src={ind_db} alt="" />
                    </div>
                    <p>&#x27F6;</p>
                    <div>
                        <img src={ind_sector} alt="" />
                    </div>
                    <p>&#x27F6;</p>
                    <div>
                        <img src={ind_indicator} alt="" />
                    </div>
                    <p>&#x27F6;</p>
                    <div>
                        <img src={ind_timeline} alt="" />
                    </div>
                    <div>
                        <img src={''} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}
