import React from 'react'
import classes from './SpecialOffer.module.css'

export default function SpecialOffer() {
    return (
        <div className={classes.special}>
            <div>Special Offer</div>
            <div>till 15th June-2024</div>
            <div>
                ৳999<span>/annually</span>
            </div>
        </div>
    )
}
