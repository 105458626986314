import Select from 'react-select'

export const SelectData = ({ options, selectOnChange, search_by }) => {
    const selectStyles = {
        control: (base) => ({
            ...base,
            outline: 0,
            background: 'white',
            // margin: '0.6rem 0 1.2rem 0',
            boxSizing: 'border-box',
            fontSize: '1rem',
            border: '0.06rem var(--grey) solid',
            borderRadius: '0.5rem',
            boxShadow: 'none',
            cursor: 'pointer',
            '&:focus': {
                borderBottom: '0.06rem var(--home-color) solid',
                width: '3rem',
            },
        }),
    }
    return (
        <>
            <Select
                maxMenuHeight={'12rem'}
                isClearable
                isSearchable
                placeholder={search_by}
                onChange={selectOnChange}
                options={options}
                styles={selectStyles}
            />
        </>
    )
}
