import classes from './Table.module.css'

export default function Table({ children, scroll }) {
    return (
        <div className={scroll ? classes.tableWrapperScroll : classes.tableWrapper}>
            <table id={classes.table} className={classes.table}>
                {children}
            </table>
        </div>
    )
}
