// import { IoIosArrowDown } from 'react-icons/io'
import Select from 'react-select'

export function SelectData({ value, options, selectOnChange, search_by, selectInputRef }) {
    const style = {
        // option: (provided, state) => ({
        //     ...provided,
        //     color: state.isSelected ? 'blue' : 'blue',
        //     backgroundColor: state.isSelected ? 'skyblue' : 'white',
        // }),
        control: (base) => ({
            ...base,
            border: '1px var(--grey2) solid',
            // This line disable the blue border
            boxShadow: 'none',
            '&:hover': {
                border: '1px var(--grey) solid',
            },
        }),
    }

    return (
        <>
            <Select
                ref={selectInputRef}
                options={options}
                value={value}
                onChange={selectOnChange}
                isClearable={true}
                isSearchable={true}
                placeholder={search_by}
                maxMenuHeight={'15rem'}
                styles={style}
            />
            <br></br>
        </>
    )
}
