import React from 'react'
import bkashIcon from '../../assets/payment/bKash.png'
import makePaymentIcon from '../../assets/payment/makepayment.png'
import classes from './PaymentProcess.module.css'
import SpecialOffer from './SpecialOffer/SpecialOffer'

export default function PaymentProcess() {
    return (
        <div className={classes.paymentProcess}>
            <div>
                <p>Payment method</p>
                <p>
                    Monthly Subscription: <span style={{ color: 'rgb(255, 53, 53)' }}>BDT 299</span>
                </p>
                <div className={classes.standard}>
                    <p>
                        Annual Subscription: <span style={{ color: 'rgb(255, 53, 53)' }}>BDT 999</span>{' '}
                        <span style={{ color: 'rgb(38, 38, 38)' }}>(save 50%)</span>
                    </p>
                    <SpecialOffer />
                </div>
                <img src={bkashIcon} alt="" />
            </div>
            <div>
                <p>
                    bKash Account Number: <span style={{ fontWeight: 'bold' }}>+880-1727611963</span> (Merchant)
                </p>
                <p>
                    Please add <span style={{ fontWeight: 'bold' }}>DT</span> in the reference
                </p>
                <img src={makePaymentIcon} alt="" />
                <p>For any query please contact: +880-1792128343</p>
            </div>
        </div>
    )
}
