export const industryList = [
    'Agriculture',
    'Automotive',
    'Building Materials',
    'Business Chamber',
    'Chemical & Engineering',
    'Consumer & Retail',
    'Economic Zone',
    'Education',
    'Electronics',
    'Energy & Power',
    'Healthcare & Pharma',
    'Housing',
    'ICT',
    'Jute & Jute Products',
    'Leisure',
    'Lifestyle & Fashion',
    'Logistics & Shipping',
    'NGO & Development',
    'Printing & Stationery',
    'Research & Consultancy',
    'RMG & Textile',
    'Security Service',
    'Security Service',
    'Start-Up',
    'Telecom',
    'Testing, Inspection & Certification (TIC)',
]

export const subIndustryList = [
    'Abrasive',
    'Advertising',
    'Agro',
    'Agro Chemical',
    'Agro Machineries',
    'Aluminum',
    'Animal Health Care',
    'Architectural Consultancy',
    'Asset Management',
    'Audit & Accounting',
    'Auto Battery',
    'Automobile',
    'Aviation',
    'Bank',
    'Beverage & Dairy',
    'Brand Consultancy',
    'Buying & Sourcing',
    'Cables & Lighting',
    'Car Trading & Showroom',
    'Cement',
    'Ceramic',
    'Charity & Trust',
    'Chemical',
    'Coal',
    'Commodity',
    'Construction',
    'Construction',
    'Courier Service',
    'Data Analytics',
    'Development',
    'Digital Health',
    'E-Commerce',
    'Education Consultancy',
    'Electronics & Home Appliances',
    'Electronics Equipment & Accessories',
    'Engineering',
    'Event Management',
    'Fashion Retail',
    'Feed',
    'Fertilizer',
    'Filling Station',
    'Fintech',
    'Fintech',
    'Fisheries',
    'FMCG',
    'Food',
    'Footwear',
    'Freight Forwarding',
    'Furniture',
    'Healthcare',
    'Healthcare Solutions',
    'Heavy Industry',
    'Hospital, Clinic & Diagnostic',
    'Hotel & Resorts',
    'Information Technology (IT)',
    'Insurance',
    'Interior Designing',
    'Investment Banking',
    'IPS & UPS',
    'Jute Mill',
    'Jute Product',
    'Knitwear',
    'Laboratory /Testing',
    'Leather & Accessories',
    'Legal Advisory',
    'Lifestyle',
    'Light Engineering',
    'LNG',
    'Logistics',
    'LPG',
    'Lubricant',
    'Management Consulting',
    'Marine Parts',
    'Market Research',
    'Media',
    'Medical Device & Accessories',
    'Merchant Bank',
    'Microfinance',
    'Mobile',
    'NBFI',
    'NGO',
    'Oil & Petroleum',
    'Packaging',
    'Paint',
    'Paper & Printing',
    'Personal Care',
    'Pharma',
    'Plastics',
    'Poultry & Livestock',
    'Power',
    'Private Equity',
    'Publishing',
    'Pump & Piping',
    'Quality Inspection',
    'Ready Mix',
    'Real Estate',
    'Restaurant & Cafe',
    'Retail / Superstore',
    'RMG',
    'RMG Accessories',
    'RMG Machinery',
    'School & College',
    'Seed',
    'Shipping',
    'Skill Development',
    'Solar & Alternative Energy',
    'Stationary',
    'Steel',
    'Stock Brokerage',
    'Technology',
    'Telecom',
    'Testing, Inspection & Certification (TIC)',
    'Textile',
    'Tobacco',
    'Tourism',
    'Trade Financing',
    'Trading',
    'Training & Skill Development',
    'University',
    'Yarn & Spinning',
]

export const companyCountryList = [
    'Australia',
    'Bangladesh',
    'Canada',
    'China',
    'Denmark',
    'Finland',
    'France',
    'Germany',
    'Hong Kong',
    'India',
    'International',
    'Ireland',
    'Italy',
    'Japan',
    'Jordan',
    'Korea, South',
    'Kuwait',
    'Malaysia',
    'Mauritius',
    'Mexico',
    'Nepal',
    'Netherlands',
    'Norway',
    'Pakistan',
    'Saudi Arabia',
    'Singapore',
    'South Korea',
    'Spain',
    'Sri Lanka',
    'Sweden',
    'Switzerland',
    'Taiwan',
    'Thailand',
    'Turkey',
    'UAE',
    'UK',
    'USA',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
]
