import React from 'react'
import classes from './TermsConditions.module.css'

export default function TermsConditions() {
    return (
        <>
            <div className={classes.terms}>
                <h1>Terms and Conditions</h1>

                <p>
                    This policy applies to everyone who visits our website, as well as to our employees, applicants,
                    clients, and business partners. It is crucial if we obtain information about you. Therefore, it
                    makes no difference if you have already become a customer of ours or even if we have a written
                    contract. This policy should make it clear to you how we handle data in each situation.
                </p>

                <p>
                    The information that is gathered about you when you visit our website, how it is used, with whom it
                    is shared, and other details are described below. Additionally, we describe your rights, such as the
                    right to information, rectification, objection, and erasure, in relation to the processing of your
                    personal data.
                </p>

                <p>
                    We will only use your personal information in ways that are permitted by the relevant data
                    protection laws. If we decide to use your Data for purposes other than those listed in this privacy
                    statement, we will let you know about them and, if necessary, get your consent.
                </p>

                <div>
                    <ul>
                        <li>
                            Controller of data
                            <p>
                                Within the legal meaning of data, only the company- Bizdata Insights has forthright
                                authority and access to the user’s data. Additionally, you can get in touch with the
                                management members. You can contact them by email at data@bizdatainsights.com.
                            </p>
                        </li>

                        <li>
                            Process and purpose of storing data
                            <p>
                                Your computer does not send your IP address to us when you visit our website, but we
                                store small text files called "Cookies" on your browser as part of this process,
                                depending on the circumstances of your visit and the settings on your computer.
                            </p>
                            <p>
                                We produce these files to improve your experience on our website. It is a component of
                                your browser's short-term memory. By making the necessary changes within your browser,
                                you can disable this short-term memory.
                            </p>
                            <p>
                                We do not combine this Data with Cookies. However, we reserve the right to later review
                                this data in individual cases if there are clear indications of wrongful use.
                            </p>
                            <p>
                                We create a subscriber account for you in our system when you create a user account with
                                us or enter into a contract with us as a customer (by subscribing, having an email sent
                                to you, etc.).
                            </p>
                            <p>
                                This account contains the master data that you have provided to us, and where
                                applicable, your billing data (‘Customer data’).
                            </p>
                            <p>
                                We store and process this data because otherwise, we cannot complete our contract with
                                you. If you sign a contract with us as a subscriber, we may also use the information you
                                provide about yourself to contact you about promotions, campaigns, and other services we
                                think you might find interesting, provided that you haven't objected to the use of your
                                data in this way.
                            </p>
                        </li>

                        <li>
                            The basis for storing data
                            <p>
                                In most cases, the most important basis for the storage is your agreement with us,
                                irrespective of whether you register on our website or if you have negotiated a contract
                                with Data Terminal for your employer. The following bases are possible:
                            </p>
                            <p>
                                <ul id={classes.list1}>
                                    <li>
                                        Your consent in accordance with the Information Communication Technology Act
                                        2006 ('the Technology Act') and the Digital Security Act, 2018 ('the Digital
                                        Security Act')
                                    </li>
                                </ul>
                            </p>
                        </li>

                        <li>
                            Location of the storage and disclosure of Data to third parties
                            <p>
                                We process your Data entirely by ourselves. To ensure global access by our customers to
                                our offering we store Customer data globally.
                            </p>
                        </li>

                        <li>
                            The procedure of storing data & disclosure of Data to third parties
                            <p>
                                We follow the standard procedure to secure the data, and as a result, create rights and
                                roles in applications, and ensure backups and upgrades are carried out in our systems.
                            </p>
                            <p>
                                While login, we store the following data from our users:
                                <ul id={classes.list2}>
                                    <li>Name</li>
                                    <li>Email Address</li>
                                    <li>Phone Number</li>
                                    <li>Company</li>
                                    <li>Designation</li>
                                    <li>Country</li>
                                    <li>Password</li>
                                </ul>
                            </p>
                            <p>
                                Bizdata Insights doesn’t share user data with any third-party company or vendor. Only
                                Bizdata Insights has the right of storing data and processing the data for the user.
                            </p>
                        </li>
                        <li>
                            Information
                            <p>
                                You have the legal right to update the information, we have stored about you at any time
                                by contacting us through (data@bizdatainsights.com)
                            </p>
                        </li>
                        <li>
                            Update and rectification of the Data Terminal
                            <p>
                                Once the data is stored in the Data Terminal, the authority of Bizdata Insights has the
                                right to make the necessary updates and rectifications. The data value can only be
                                updated if the source of the data makes necessary changes and upgrades.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
