import React from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import classes from './Contact.module.css'

export default function Contact(props) {
    return (
        <>
            <div className={classes.wrapper}>
                <p>
                    Please contact us at <span>+8801792128343</span>
                </p>
                <p>
                    or mail us at <span>data@bizdatainsights.com</span> to get download.
                </p>
                <button>
                    <AiFillCloseCircle className={classes.closeIcon} onClick={() => props.setPopUp(false)} />
                </button>
            </div>
        </>
    )
}
