import React from 'react'
import FaqComponent from 'react-faq-component'
import classes from './Faq.module.css'
import { faqConfig, faqData, faqStyles } from './FaqDetails'

export default function Faq() {
    return (
        <>
            <div id="FAQ" className={classes.FaqComponent}>
                <h1>Frequently Asked Questions</h1>
                <div>
                    <FaqComponent data={faqData} styles={faqStyles} config={faqConfig} />
                </div>
            </div>
        </>
    )
}
