import React from 'react'
import classes from './Overview.module.css'

export default function Overview(props) {
    const { p1, p2, p3, v1, v2, v3 } = props

    return (
        <>
            <div className={classes.overview}>
                <div>
                    <p>{v1}</p>
                    <p>{p1}</p>
                </div>
                <div>
                    <p>{v2}</p>
                    <p>{p2}</p>
                </div>
                <div>
                    <p>{v3}</p>
                    <p>{p3}</p>
                </div>
            </div>
        </>
    )
}
