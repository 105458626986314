import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { BiLogOut } from 'react-icons/bi'
import { MdBarChart } from 'react-icons/md'
import { SiDatabricks } from 'react-icons/si'
import { useNavigate } from 'react-router-dom'
import logo from '../../../assets/favicon/logo-main.png'
import menu from '../../../assets/menu.png'
import { Auth, UserInfo } from '../../../contexts/allContext'
import { api, frontEnd } from '../../../utils/apiToken'
import { faqTitle } from '../../Faq/FaqDetails'
import classes from './Navbar.module.css'

const _ = require('lodash')

export function Navbar() {
    // const [popUp, setPopUp] = useState(false)
    const [active, setActive] = useState(false)
    // const [clicked, setClicked] = useState(false)
    const navigate = useNavigate()
    const { stateAuth, dispatchAuth } = useContext(Auth)
    const { stateUser, dispatchUser } = useContext(UserInfo)
    const userInfo = stateUser.info

    const handleLogout = async (e) => {
        e.preventDefault()
        const logout = await axios.get(`${api}/login/logout`, {
            headers: {
                'Access-Control-Allow-Origin': frontEnd,
            },
            withCredentials: true,
        })
        if (logout) {
            dispatchAuth({ type: 'remove' })
            dispatchUser({ type: 'remove' })
        } else {
            alert('unable to logout')
        }
    }

    const handleCompanyDatabase = () => {
        navigate('/company-database')
    }

    useEffect(() => {
        const fetch = async () => {
            let logFetch = await axios.get(`${api}/login/auth`, {
                headers: {
                    'Access-Control-Allow-Origin': frontEnd,
                    Authorization: `Bearer ${stateAuth.token}`,
                },
                withCredentials: true,
            })

            let log = await logFetch.data

            if (log.msg === 'found_token') {
                dispatchAuth({ type: 'token', payload: stateAuth.token })
                dispatchUser({ type: 'set', payload: log })
            } else {
                dispatchAuth({ type: 'remove' })
                dispatchUser({ type: 'remove' })
            }
        }
        try {
            stateAuth.auth && stateUser.info.name === undefined && fetch()
        } catch (e) {
            dispatchAuth({ type: 'remove' })
            dispatchUser({ type: 'remove' })
        }
    }, [stateAuth, stateUser])

    return (
        <>
            {/* {popUp && (
                <PopUpTemplate>
                    <LoginPopUp popUp={popUp} setPopUp={setPopUp} route={'/company-database'} />
                </PopUpTemplate>
            )} */}
            <div className={classes.nav}>
                <div>
                    <div className={classes.dropDownMenuClick}>
                        <button type="button" onClick={() => setActive(!active)}>
                            <img src={menu} alt="" />
                        </button>
                        {active && <div className={classes.overlay} onClick={(e) => setActive(false)}></div>}
                        <div
                            className={active ? classes.dropDownContentMenuClick : classes.dropDownContentMenuInactive}>
                            {/* <a href="#AboutUs">About Us</a> */}
                            <div className={classes.dropRightMenu}>
                                <button type="button">
                                    FAQ <span id={classes.downrightSymbol}>›</span>
                                </button>
                                <div onClick={(e) => setActive(false)} className={classes.dropRightContentMenu}>
                                    {faqTitle.map((FAQ_Title, index) => {
                                        return (
                                            <span key={index}>
                                                <a href="/#FAQ">{FAQ_Title}</a>
                                            </span>
                                        )
                                    })}
                                </div>
                            </div>
                            <a href="/#ContactUs">Contact Us</a>
                            <a href="/terms-and-conditions">Terms and Conditions</a>
                            <a href="/indicators">Indicators</a>
                            <a href="/how-it-works">How It Works</a>
                            <a href="/payment-procedure">Payment Method</a>
                        </div>
                    </div>
                    <a href="/">
                        <img src={logo} alt="" />
                    </a>
                </div>

                <div>
                    <div className={classes.dropDownMenu}>
                        <button className={classes.navBtn}>
                            <a href="/">Home</a>
                            {/* <span className={classes.dropSymbolNone}></span> */}
                            <p className={classes.underline}></p>
                        </button>
                    </div>
                    <div className={classes.dropDownMenu}>
                        <button>
                            Dashboards <p className={classes.dropSymbol}>›</p>
                            <p className={classes.underline}></p>
                        </button>
                        <div className={classes.dropDownContentMenu}>
                            <a href={stateAuth.auth ? '/dashboard' : '/#Economic'}>Economic Dashboard</a>
                            <a href={stateAuth.auth ? '/dashboard' : '/#Industry'}>Industry Dashboard</a>
                            <a href={stateAuth.auth ? '/dashboard' : '/#Company'}>Company Dashboard</a>
                        </div>
                    </div>
                    <div className={classes.dropDownMenu}>
                        <button onClick={() => handleCompanyDatabase()}>
                            Company Database
                            {/* <span className={classes.dropSymbolNone}></span> */}
                            <p className={classes.underline}></p>
                        </button>
                    </div>
                    <div className={classes.dropDownMenu}>
                        <button onClick={() => navigate('/indicators')}>
                            Indicators
                            {/* <span className={classes.dropSymbolNone}></span> */}
                            <p className={classes.underline}></p>
                        </button>
                    </div>

                    <div className={classes.dropDownMenu}>
                        <button>
                            <a href="/#HIW">How It Works</a>
                            {/* <span className={classes.dropSymbolNone}></span> */}
                            <p className={classes.underline}></p>
                        </button>
                    </div>

                    <div className={classes.dropDownMenu}>
                        <button>
                            <a href="/#SUBS">Subscription Plan</a>
                            {/* <span className={classes.dropSymbolNone}></span> */}
                            <p className={classes.underline}></p>
                        </button>
                    </div>

                    <div className={classes.dropDownMenu}>
                        <button>
                            <a href="/#FAQ">FAQ</a>
                            {/* <span className={classes.dropSymbolNone}></span> */}
                            <p className={classes.underline}></p>
                        </button>
                    </div>

                    <div className={classes.dropDownMenu}>
                        <button>
                            <a href="/#ContactUs">Contact Us</a>
                            {/* <span className={classes.dropSymbolNone}></span> */}
                            <p className={classes.underline}></p>
                        </button>
                    </div>

                    {stateAuth.auth === true ? (
                        <>
                            <div className={classes.dropDownMenu}>
                                <button id={classes.userName}>
                                    {userInfo.name}
                                    <p className={classes.dropSymbol}>›</p>
                                </button>
                                <button id={classes.userNameMob}>
                                    {_.isUndefined(userInfo.name) ? userInfo.name : userInfo.name.split(' ')[0]}
                                    <p className={classes.dropSymbol}>›</p>
                                </button>
                                <div className={classes.dropDownContentProfile}>
                                    <button id={classes.btnDashboard} onClick={() => navigate('/dashboard')}>
                                        <MdBarChart className={classes.icon1} />
                                        Dashboard Page
                                    </button>
                                    <button id={classes.btnDashboard} onClick={() => navigate('/company-database')}>
                                        <SiDatabricks className={classes.icon2} />
                                        Company Database
                                    </button>
                                    <button id={classes.btnDashboard} onClick={(e) => handleLogout(e)}>
                                        <BiLogOut className={classes.icon3} />
                                        Logout
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className={classes.dropDownMenu}>
                            <button
                                id={classes.btnSignUp}
                                onClick={() => document.getElementById('SUBS').scrollIntoView({ behavior: 'smooth' })}>
                                {/* <a href="/#SUBS">Sign Up</a> */}
                                Sign Up
                            </button>
                            <button id={classes.btnSignUp} onClick={() => navigate('/login')}>
                                Login
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
