import React from 'react'
// import contactUsImage from '../../assets/LandingPage/contactus.png'
import customer_support from '../../assets/contactus/customer_support.png'
import email from '../../assets/contactus/email.png'
import location from '../../assets/contactus/location.png'
import classes from './ContactUs.module.css'

export default function ContactUs() {
    return (
        <>
            <div id="ContactUs" className={classes.contactUs}>
                <div>
                    <p>Need To Talk?</p>
                    <p>Get in touch with us. We're happy to help you.</p>
                </div>
                <div>
                    <div>
                        <div>
                            <img src={email} alt="" />
                        </div>
                        <p>Email</p>
                        <p>data@bizdatainsights.com</p>
                    </div>
                    <div>
                        <div>
                            <img src={customer_support} alt="" />
                        </div>
                        <p>Customer Support</p>
                        <p>+880-1792128343</p>
                    </div>
                    <div>
                        <div>
                            <img src={location} alt="" />
                        </div>
                        <p>Location</p>
                        <p>Level-2, House-6, Road-2,</p>
                        <p>Block-L, Banani, Dhaka-1215</p>
                    </div>
                </div>
            </div>
        </>
    )
}
