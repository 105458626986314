import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Colors } from 'chart.js'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useCallback } from 'react'
import { Bar } from 'react-chartjs-2'
import { CSVLink } from 'react-csv'
import { useNavigate } from 'react-router'
import { useReactToPrint } from 'react-to-print'
import downloadCsvIcon from '../../../assets/downloadIcon/csv.png'
import downloadImageIcon from '../../../assets/downloadIcon/graph.png'
import downloadImageIconPdf from '../../../assets/downloadIcon/pdf.png'
import { UserInfo } from '../../../contexts/allContext'
import MultiRange from '../../MultiRange/MultiRange'
import classes from './BarChart.module.css'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Colors)

export default function BarChartBudget(props) {
    const navigate = useNavigate()
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info

    const [validationAlert, setAlert] = useState(false)

    const handlePopUpMessage = () => {
        setAlert(true)
        setTimeout(function () {
            setAlert(false)
        }, 3000)
    }

    const [validationAlertStandard, setAlertStandard] = useState(false)

    const handlePopUpMessageStandard = () => {
        setAlertStandard(true)
        setTimeout(function () {
            setAlertStandard(false)
        }, 3000)
    }

    const ref = useRef(null)
    const downloadImage = useCallback(() => {
        const link = document.createElement('a')
        link.download = 'chart.png'
        link.href = ref.current.toBase64Image()
        link.click()
    }, [])

    const pdfRef = useRef(null)
    const downloadImagePdf = useReactToPrint({
        content: () => pdfRef.current,
        documentTitle: 'graph',
        // onAfterPrint: () => alert('print success'),
    })

    const { dataForExport, domainSearch, dash, search, dataValueY, dataValueX, legendY, legendX, timeline } = props

    const [xRange, setXRange] = useState(dataValueX)
    const [yRange, setYRange] = useState(dataValueY)

    // const [labelRange, setLabelRange] = useState(50)
    // useEffect(() => {
    //     const updateChart = async () => {
    //         const rangeValueX = await dataValueX.slice(0, labelRange)
    //         setXRange(rangeValueX)
    //         const rangeValueY = await dataValueY.slice(0, labelRange)
    //         setYRange(rangeValueY)
    //     }
    //     updateChart()
    // }, [dataValueX, dataValueY, labelRange])

    const [minValue, set_minValue] = useState(0)
    const [maxValue, set_maxValue] = useState(100)

    useEffect(() => {
        const updateChartMulti = async () => {
            const rangeValueX = await dataValueX.slice(minValue, maxValue)
            setXRange(rangeValueX)
            const rangeValueY = await dataValueY.slice(minValue, maxValue)
            setYRange(rangeValueY)
        }
        updateChartMulti()
    }, [dataValueX, dataValueY, minValue, maxValue])

    const handleInput = (e) => {
        set_minValue(e.minValue)
        set_maxValue(e.maxValue)
    }

    const labels = dataValueX ? xRange : ''
    const data = {
        labels,
        datasets: [
            {
                label: timeline ? timeline : 'No data found',
                data: dataValueY ? yRange : '',
                backgroundColor: ['#100b95'],
                hoverBackgroundColor: '#013893',
                borderColor: ['#100b95'],
                hoverBorderColor: ['#013893'],
                // categoryPercentage: 0.1,
                // barPercentage: 0.2,
                // barThickness: dataValueY.length < 4 ? 20 : 70,
            },
            // {
            //     label: timeline ? timeline : 'No data found',
            //     data: dataValueY ? dataValueY : '',
            //     borderColor: '#3e95cd',
            //     backgroundColor: 'rgb(62,149,205)',
            //     borderWidth: 2,
            //     type: 'line',
            //     fill: false,
            // },
        ],
    }

    // let delayed

    const options = {
        // animation: {
        //     onComplete: () => {
        //         delayed = true
        //     },
        //     delay: (context) => {
        //         let delay = 0
        //         if (context.type === 'data' && context.mode === 'default' && !delayed) {
        //             delay = context.dataIndex * 300 + context.datasetIndex * 100
        //         }
        //         return delay
        //     },
        // },
        indexAxis: 'x',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            colors: {
                enabled: false,
            },
            legend: {
                position: 'top',
                display: false,
            },
            title: {
                display: true,
                text: search && `${search} (${legendX})`,
                font: {
                    size: 18,
                },
            },
            backgroundColor: 'rgba(255, 99, 132)',
        },
        scales: {
            x: {
                grid: {
                    borderDash: [1, 20],
                    color: 'rgba(0, 0, 0, 0)',
                },
                title: {
                    display: true,
                    text: legendY ? `Source: ${legendY}` : '',
                    font: {
                        size: 13,
                    },
                },
            },
            y: {
                grid: {
                    color: 'white',
                },
                title: {
                    display: true,
                    text: legendX ? `Unit of Measurement: ${legendX}` : '',
                    font: {
                        size: 13,
                    },
                },
            },
            // myScale: {
            //     type: 'linear',
            //     position: 'bottom', // `axis` is determined by the position as `'y'`
            // },
        },
    }

    return (
        <>
            <div className={classes.chartWrapper} ref={pdfRef}>
                <Bar ref={ref} data={data} options={options} />
            </div>
            {/* <div className={classes.pointsRange}>
                <input
                    onChange={(e) => setLabelRange(e.target.value)}
                    type="range"
                    id={classes.pointsRange}
                    min="1"
                    max={dataValueX.length}
                    value={labelRange}
                    list="markers"
                />
                <MultiRangeSlider
                    min={0}
                    max={dataValueX.length}
                    step={1}
                    minValue={minValue}
                    maxValue={maxValue}
                    onInput={(e) => {
                        handleInput(e)
                    }}
                    // baseClassName={classes.multiRangeSlider}
                    ruler={false}
                    label={false}
                    // labels={dataValueX}
                    barInnerColor={'var(--home-color)'}
                />
            </div> */}
            <MultiRange
                dataValueX={dataValueX}
                xRange={xRange}
                minValue={minValue}
                maxValue={maxValue}
                handleInput={handleInput}
            />
            <div className={classes.download}>
                <CSVLink
                    onClick={(e) => {
                        if (userInfo.restriction === 1) {
                            if (userInfo.role === 'standard_m' && props.downloadCount <= props.downloadLimit) {
                                props.handleUpdateDownload(props.downloadCount + 1)
                                props.setCheck(true)
                                return true
                            } else if (userInfo.role === 'standard_y' && props.downloadCount <= props.downloadLimit) {
                                props.handleUpdateDownload(props.downloadCount + 1)
                                props.setCheck(true)
                                return true
                            } else {
                                alert(
                                    'Your download limit was exceeded! Please contact us at +880-1792128343 or mail us at data@bizdatainsights.com'
                                )
                                return false
                            }
                        } else if (
                            (userInfo.role === 'admin' ||
                                userInfo.role === 'standard_y' ||
                                userInfo.role === 'standard_m') &&
                            userInfo.restriction === 0
                        ) {
                            handlePopUpMessageStandard()
                            return false
                        } else {
                            handlePopUpMessage()
                            return false
                        }
                    }}
                    className={classes.btnCsvExport}
                    data={
                        dataForExport
                            ? dash === 'Company'
                                ? dataForExport.map((e) => ({
                                      Company: domainSearch,
                                      Indicator: search,
                                      'Budget Year': e['Budget Year'],
                                      'Budget Value': e['Budget Value'],
                                      Unit: e.Unit,
                                      Source: e.Source,
                                  }))
                                : dash === 'Industry'
                                ? dataForExport.map((e) => ({
                                      Industry: domainSearch,
                                      Indicator: search,
                                      'Budget Year': e['Budget Year'],
                                      'Budget Value': e['Budget Value'],
                                      Unit: e.Unit,
                                      Source: e.Source,
                                  }))
                                : dataForExport.map((e) => ({
                                      Indicator: search,
                                      'Budget Year': e['Budget Year'],
                                      'Budget Value': e['Budget Value'],
                                      Unit: e.Unit,
                                      Source: e.Source,
                                  }))
                            : ''
                    }
                    filename={
                        dash === 'Company'
                            ? `${domainSearch}_${search}_${timeline}`
                            : dash === 'Industry'
                            ? `${domainSearch}_${search}_${timeline}`
                            : `${search}_${timeline}`
                    }>
                    <img src={downloadCsvIcon} alt="" />
                    <p>Export Data as csv</p>
                </CSVLink>
                {/* onClick={() => downloadImage()} */}
                {/* onClick={() => downloadImagePdf()} */}
                <button
                    onClick={() => {
                        if (userInfo.restriction === 1) {
                            downloadImage()
                        } else if (
                            (userInfo.role === 'admin' ||
                                userInfo.role === 'standard_y' ||
                                userInfo.role === 'standard_m') &&
                            userInfo.restriction === 0
                        ) {
                            handlePopUpMessageStandard()
                        } else {
                            handlePopUpMessage()
                        }
                    }}>
                    <img src={downloadImageIcon} alt="" />
                    <p>Export Graph as png</p>
                </button>
                <button
                    onClick={() => {
                        if (userInfo.restriction === 1) {
                            downloadImagePdf()
                        } else if (
                            (userInfo.role === 'admin' ||
                                userInfo.role === 'standard_y' ||
                                userInfo.role === 'standard_m') &&
                            userInfo.restriction === 0
                        ) {
                            handlePopUpMessageStandard()
                        } else {
                            handlePopUpMessage()
                        }
                    }}>
                    <img src={downloadImageIconPdf} alt="" />
                    <p>Export Graph as pdf</p>
                </button>

                <div
                    style={{ display: validationAlert || validationAlertStandard ? '' : 'none' }}
                    className={classes.popUpMessage}>
                    {validationAlert && <p>Please upgrade to standard account to avail this service</p>}
                    {validationAlertStandard && (
                        <p onClick={() => navigate('/payment-procedure')}>
                            Please complete the payment and wait for your payment confirmation email.
                            <br></br>Click for the payment method.
                        </p>
                    )}
                </div>
            </div>
        </>
    )
}
