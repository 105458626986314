import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import eyeHide from '../../../../assets/showhide/eyeHide.png'
import eyeOpen from '../../../../assets/showhide/eyeOpen.png'
import { Auth } from '../../../../contexts/allContext'
import { api, frontEnd } from '../../../../utils/apiToken'
import classes from '../Login.module.css'

export default function LoginContainer(props) {
    const { stateAuth, dispatchAuth } = useContext(Auth)

    const [alert, setAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const navigate = useNavigate()

    const [showPass, setShowPass] = useState(false)
    const [userInfo, setUserInfo] = useState({
        email: '',
        password: '',
    })

    const handleLogin = async (e) => {
        e.preventDefault()

        let logFetch = await axios.post(
            `${api}/login/login`,
            { email: userInfo.email, password: userInfo.password },
            {
                headers: {
                    'Access-Control-Allow-Origin': frontEnd,
                },
                withCredentials: true,
            }
        )

        let log = await logFetch.data

        if (log.Token) {
            dispatchAuth({ type: 'token', payload: log.Token })
        } else {
            setAlertMessage(log.message)
            setAlert(true)
            setTimeout(function () {
                setAlert(false)
            }, 3000)
            if (log.flag === '1') {
                props.setActivePopUp(true)
                props.setRole(log.role)
            }
        }
    }

    const handleSignUp = (role) => {
        navigate(`/register/${role}`)
    }

    useEffect(() => {
        if (stateAuth.auth) {
            navigate('/dashboard')
        }
    }, [navigate, stateAuth])
    return (
        <>
            <div>
                <p>Sign In</p>

                <p>Please enter your credentials to login</p>
                {alert && <p className={classes.alertMessage}>{alertMessage}</p>}
                <form id={classes.loginForm} onSubmit={(e) => handleLogin(e)}>
                    <p className={classes.p2}>Email address</p>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        name="email"
                        value={userInfo.email}
                        onChange={(e) => {
                            e
                                ? setUserInfo((prev) => {
                                      return { ...prev, email: e.target.value }
                                  })
                                : setUserInfo((prev) => {
                                      return { ...prev, email: NaN }
                                  })
                        }}
                        required
                    />
                    <p className={classes.p2}>Password</p>
                    <input
                        type={showPass ? 'text' : 'password'}
                        placeholder="Enter your password"
                        name="password"
                        value={userInfo.password}
                        onChange={(e) => {
                            e
                                ? setUserInfo((prev) => {
                                      return { ...prev, password: e.target.value }
                                  })
                                : setUserInfo((prev) => {
                                      return { ...prev, password: NaN }
                                  })
                        }}
                        required
                    />
                    {userInfo.password && (
                        <button className={classes.eyeBtn} type="button" onClick={() => setShowPass(!showPass)}>
                            <img src={showPass ? eyeOpen : eyeHide} alt="" />
                        </button>
                    )}
                    <button className={classes.btnLogin} type="submit">
                        Sign In
                    </button>
                </form>
                {/* <a href="/#SUBS">Sign Up</a> */}
                <p className={classes.message}>
                    Not registered?
                    <button type="button" onClick={(e) => handleSignUp('individual_user')}>
                        Sign Up
                    </button>
                </p>
            </div>
        </>
    )
}
