import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import Dashboard from '../components/Dashboard/Dashboard'
import Footer from '../components/Footer/Footer'
import Layout from '../components/Layout/Layout'
import { UserInfo } from '../contexts/allContext'
import { api } from '../utils/apiToken'

export default function DashboardPage() {
    const { stateUser } = useContext(UserInfo)
    const [downloadCount, setDownloadCount] = useState(0)
    const [downloadLimit, setDownloadLimit] = useState(0)
    const [check, setCheck] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`${api}/user/user_info/${stateUser.info.id}`)
            const data = await response.data
            setDownloadCount(data.download)
            setDownloadLimit(data.download_limit)
            check && setCheck(false)
        }
        fetchData()
    }, [check, stateUser])

    const handleUpdateDownload = async (newDownloadCount) => {
        const details = { download: newDownloadCount }
        try {
            const response = await axios.patch(`${api}/user/download_times/${stateUser.info.id}`, details)
            if (response) {
                // console.log('success')
            } else {
                alert('Failed!')
            }
        } catch {
            alert('Internal Server Error! Try again later.')
        }
    }
    return (
        <Layout>
            <Dashboard
                downloadCount={downloadCount}
                downloadLimit={downloadLimit}
                handleUpdateDownload={handleUpdateDownload}
                setCheck={setCheck}
            />
            <Footer />
        </Layout>
    )
}
