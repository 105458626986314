import React, { useContext } from 'react'
import { useNavigate } from 'react-router'
import { UserInfo } from '../../contexts/allContext'
import SpecialOffer from './SpecialOffer/SpecialOffer'
import classes from './Subscription.module.css'

export default function Subscription() {
    // const { stateAuth, dispatchAuth } = useContext(Auth)
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info
    // console.log(userInfo.role)
    const navigate = useNavigate()
    const handleIndividual = (role) => {
        if (role === 'individual_user' && userInfo.role === 'individual_user') {
            alert('You are already subscribed as a basic user! To get standard subscription, please contact us.')
        } else if (role === 'individual_user' || userInfo.role === 'standard_m' || userInfo.role === 'standard_y') {
            alert('You are already subscribed as a standard user. To get more information please contact us!')
        } else {
            navigate(`/register/individual_user`)
        }
    }

    const handleStandard = (role) => {
        if (role === 'standard_user' && (userInfo.role === 'standard_m' || userInfo.role === 'standard_y')) {
            alert('You are already subscribed as a standard user! For more information please contact us.')
        } else if (role === 'standard_user' && userInfo.role === 'individual_user') {
            alert(
                'You are already subscribed as a basic user. To get subscription as a standard user please contact us!'
            )
        } else {
            navigate(`/register/standard_user`)
        }
    }

    return (
        <>
            <div id="SUBS" className={classes.subscription}>
                <h1>Subscription Plan for Data Terminal</h1>
                <div>
                    <div>
                        <p>Basic</p>
                        <p>Perfect for everyone</p>
                        <p>Free</p>
                        <p></p>
                        <p>What you'll get</p>
                        <ul>
                            <li>Immediate Access to All Dashboards for 14 days</li>
                        </ul>
                        <button type="button" onClick={(e) => handleIndividual('indivisual_user')}>
                            Subscribe
                        </button>
                    </div>

                    <div className={classes.standard}>
                        <p>Standard</p>
                        <p>Perfect for personal use</p>
                        <p className={classes.plan}>
                            $4 <span id={classes.plan}>/monthly or</span> $29 <span id={classes.plan}>/annually</span>
                            <br />
                            ৳299
                            <span id={classes.plan}>/monthly or </span>
                            <span id={classes.cross}>৳1,999</span>
                            <span id={classes.plan}>/annually </span>
                        </p>
                        <p></p>
                        <p>What you'll get</p>
                        <ul>
                            <li>Full Access to All Dashboards</li>
                            <br></br>
                            <li>Export Graph as PDF & PNG </li>
                            <br></br>
                            <li>
                                Download Data in CSV Format <div>(20 times per month)</div>
                            </li>
                            <br></br>
                        </ul>
                        <button type="button" onClick={(e) => handleStandard('standard_user')}>
                            Subscribe
                        </button>
                        <SpecialOffer />
                    </div>

                    <div>
                        <p>Enterprise</p>
                        <p>Perfect for corporate use</p>
                        <p>Let's Talk</p>
                        <p></p>
                        <p>What you'll get</p>
                        <ul>
                            <li>Full Access to All Dashboards</li>
                            <br></br>

                            <li>Export Graph as PDF & PNG </li>
                            <br></br>
                            <li>
                                Download Data in CSV Format <div></div>
                            </li>
                            <br></br>
                            <li style={{ color: 'var(--home-light)' }}>Customized Profile </li>
                            <br></br>
                            <li style={{ color: 'var(--home-light)' }}>Exclusive Customer Support </li>
                            <br></br>
                        </ul>
                        <a href="/#ContactUs">
                            <button type="button">Contact Us</button>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}
