import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import React, { useContext, useEffect, useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import notFoundImage from '../../assets/company-database/no_data_found.jpg'
import { Auth, UserInfo } from '../../contexts/allContext'
import { LoadingBar, PopUpTemplate, SelectField } from '../Resource'
import classes from './CompanyDatabase.module.css'
import CompanyPdf from './CompanyPdf/CompanyPdf'
import Contact from './Contact/Contact'
import SearchTable from './SearchTable/SearchTable'

export default function CompanyDatabase(props) {
    const [popUp, setPopUp] = useState(false)
    // const [loader, setLoader] = useState(false)
    // const [companyInfo, setCompanyInfo] = useState([])
    // const downloadPdf = (pdfClass) => {
    //     setLoader(true)
    //     // const capture = document.querySelector(`.${classes.wrapper}`)

    //     // const pdf = new jsPDF('p', 'mm', 'a4', true)
    //     // pdf.text('Comapany Information', 10, 10)
    //     // pdf.text(`Name: ${companyInfo.company}`, 10, 20)
    //     // pdf.text(`Industry: ${companyInfo.industry}`, 10, 27)
    //     // pdf.text(`Sub Industry: ${companyInfo.subindustry}`, 10, 34)
    //     // pdf.save(`${companyInfo.company}.pdf`)
    //     // setLoader(false)

    //     // image pdf
    //     const capture = pdfClass
    //     html2canvas(capture).then((canvas) => {
    //         const imgData = canvas.toDataURL('img/png')
    //         const pdf = new jsPDF('p', 'mm', 'a4', true)
    //         const pdfWidth = pdf.internal.pageSize.getWidth()
    //         const pdfHeight = pdf.internal.pageSize.getHeight()
    //         const imgWidth = canvas.width
    //         const imgHeight = canvas.height
    //         const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
    //         const imgX = (pdfWidth - imgWidth * ratio) / 2
    //         const imgY = 0
    //         pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio)
    //         setLoader(false)
    //         pdf.save(`${companyInfo.company}.pdf`)
    //     })
    // }
    const { stateAuth } = useContext(Auth)
    const { stateUser } = useContext(UserInfo)

    // Company
    const [accessCompany, setAccessCompany] = useState(true)
    const [selectComp, setSelectComp] = useState(0)
    localStorage.setItem('selectCompany', selectComp)
    const selectCompany = localStorage.getItem('selectCompany')

    useEffect(() => {
        const fetch = async () => {
            if (!stateAuth.auth && selectCompany > 30) {
                accessCompany && setAccessCompany(false)
            } else if (stateAuth.auth && stateUser.info.restriction === 0 && selectCompany > 30) {
                accessCompany && setAccessCompany(false)
            } else {
                !accessCompany && setAccessCompany(true)
            }
        }
        fetch()
    }, [stateAuth, stateUser, selectCompany, setAccessCompany])

    // Industry
    const [access, setAccess] = useState(true)
    const [selectInd, setSelectInd] = useState(0)
    localStorage.setItem('selectIndustry', selectInd)
    const selectIndustry = localStorage.getItem('selectIndustry')

    useEffect(() => {
        const fetch = async () => {
            if (!stateAuth.auth && selectIndustry > 1) {
                access && setAccess(false)
            } else if (stateAuth.auth && stateUser.info.restriction === 0 && selectIndustry > 1) {
                access && setAccess(false)
            } else {
                !access && setAccess(true)
            }
        }
        fetch()
    }, [stateAuth, stateUser, selectIndustry, setAccess])

    // Sub Industry
    const [accessSubInd, setAccessSubInd] = useState(true)
    const [selectSubInd, setSelectSubInd] = useState(0)
    localStorage.setItem('selectSubIndustry', selectSubInd)
    const selectSubIndustry = localStorage.getItem('selectSubIndustry')

    useEffect(() => {
        const fetch = async () => {
            if (!stateAuth.auth && selectSubInd > 1) {
                accessSubInd && setAccessSubInd(false)
            } else if (stateAuth.auth && stateUser.info.restriction === 0 && selectSubInd > 1) {
                accessSubInd && setAccessSubInd(false)
            } else {
                !accessSubInd && setAccessSubInd(true)
            }
        }
        fetch()
    }, [stateAuth, stateUser, selectSubInd, accessSubInd])

    // Next
    const [accessNext, setAccessNext] = useState(true)
    const [selectN, setSelectN] = useState(0)
    localStorage.setItem('selectNext', selectN)
    const selectNext = localStorage.getItem('selectNext')

    useEffect(() => {
        const fetch = async () => {
            if (!stateAuth.auth && selectNext > 1) {
                accessNext && setAccessNext(false)
            } else if (stateAuth.auth && stateUser.info.restriction === 0 && selectNext > 1) {
                accessNext && setAccessNext(false)
            } else {
                !accessNext && setAccessNext(true)
            }
        }
        fetch()
    }, [stateAuth, stateUser, selectNext, accessNext, setAccessNext])

    const checkAccess = () => {
        // alert('Please contact us at +8801792128343 or mail us at data@bizdatainsights.com')
        setPopUp(true)
    }

    return (
        <>
            {/* {popUp && (
                <PopUpTemplate>
                    <CompanyPdf companyInfo={companyInfo} downloadPdf={downloadPdf} setPopUp={setPopUp} />
                </PopUpTemplate>
            )} */}
            {popUp && (
                <PopUpTemplate>
                    <Contact setPopUp={setPopUp} />
                </PopUpTemplate>
            )}
            <div className={classes.wrapper}>
                <div className={classes.title}>
                    Welcome to <span>COMPANY</span>
                    <span>DATABASE</span>
                </div>
                <div className={classes.about}>
                    <p>Explore Business Information of 50,000+ Companies in Bangladesh</p>
                    <p>
                        Get access to{' '}
                        <span>
                            Company Name, Company Basic Information, Product Descriptions, Brand Name, Financial
                            Information, Shareholder Information, HR Information and Contact Information
                        </span>{' '}
                        of more than 50,000 companies of Bangladesh
                    </p>
                </div>
                <div className={classes.companyDatabase}>
                    <div className={classes.searchBar}>
                        <input
                            type="text"
                            placeholder="Search by company ..."
                            value={props.search}
                            onChange={(e) => {
                                if (accessCompany) {
                                    props.setSearch(e.target.value)
                                    setSelectComp(selectComp + 1)
                                } else {
                                    checkAccess()
                                }
                            }}
                            // required
                        />
                        {props.search && (
                            <button
                                type="button"
                                className={classes.searchBtnRemove}
                                onClick={() => props.setSearch('')}>
                                X
                            </button>
                        )}
                        <button type="button" className={classes.searchBtn} onClick={() => props.handleSearch()}>
                            <BiSearch className={classes.searchIcon} />
                        </button>
                    </div>

                    <div className={classes.filter}>
                        <div>
                            <SelectField
                                search_by="-- Filter by Industry --"
                                borderColor={'lightgrey'}
                                borderHover={'grey'}
                                searchable={true}
                                clearable={true}
                                options={props.industryOptions}
                                value={props.industry}
                                selectOnChange={(e) => {
                                    if (e) {
                                        if (access) {
                                            setSelectInd(selectInd + 1)
                                            props.setIndustry(e)
                                        } else {
                                            checkAccess()
                                        }
                                    } else {
                                        props.setIndustry('')
                                    }
                                }}
                            />
                        </div>
                        <div>
                            <SelectField
                                search_by="-- Filter by Sub Industry --"
                                borderColor={'lightgrey'}
                                borderHover={'grey'}
                                searchable={true}
                                clearable={true}
                                options={props.subIndustryOptions}
                                value={props.subIndustry}
                                selectOnChange={(e) => {
                                    if (e) {
                                        if (accessSubInd) {
                                            setSelectSubInd(selectSubInd + 1)
                                            props.setSubIndustry(e)
                                        } else {
                                            checkAccess()
                                        }
                                    } else {
                                        props.setSubIndustry('')
                                    }
                                }}
                            />
                        </div>
                        <div>
                            <SelectField
                                search_by="-- Filter by Country --"
                                borderColor={'lightgrey'}
                                borderHover={'grey'}
                                searchable={true}
                                clearable={true}
                                options={props.countryOptions}
                                value={props.country}
                                selectOnChange={(e) => {
                                    if (e) {
                                        props.setCountry(e)
                                    } else {
                                        props.setCountry('')
                                    }
                                }}
                            />
                        </div>
                    </div>

                    {props.data.length === 0 && !props.loading ? (
                        <div className={classes.notFound}>
                            <img src={notFoundImage} alt="" />
                            <p>No search result found!</p>
                        </div>
                    ) : (
                        <SearchTable
                            data={props.data}
                            showDataDetails={props.showDataDetails}
                            slNo={props.slNo}
                            // setCompanyInfo={setCompanyInfo}
                            // setPopUp={setPopUp}
                        />
                    )}
                    {props.data.length === 0 && props.loading && <LoadingBar height={15} color={'var(--home-color)'} />}
                </div>
                {props.data.length !== 0 && (
                    <div className={classes.pagination}>
                        <button
                            disabled={props.slNo === 1 && true}
                            type="button"
                            onClick={() => {
                                props.previousHandle()
                            }}>
                            {'« Previous'}
                        </button>
                        <button
                            disabled={
                                Math.ceil(props.slNo / props.page) === Math.ceil(props.countData / props.page)
                                    ? true
                                    : false
                            }
                            type="button"
                            onClick={() => {
                                if (accessNext) {
                                    props.nextHandle()
                                    setSelectN(selectN + 1)
                                } else {
                                    checkAccess()
                                }
                            }}>
                            {'Next »'}
                        </button>
                        <div>
                            <span style={{ color: 'var(--home)', fontWeight: 'bold' }}>Page: </span>
                            {Math.ceil(props.slNo / props.page)}/{Math.ceil(props.countData / props.page)}
                        </div>
                    </div>
                )}
            </div>
            {/* <div>
                <div>Power BI</div>
                <div>
                    <iframe
                        title="Pragati Life Insurance Final"
                        className={classes.powerBi}
                        src="https://app.powerbi.com/view?r=eyJrIjoiMGRjM2JiYmItNGM5Mi00NWRiLTk5MDUtNTk1YjlkNTEyYWNlIiwidCI6IjAzNTIxNWIyLTYwYjgtNGQ1OC04YmY0LWVhMDQ4MGQwMmQ0ZCIsImMiOjEwfQ%3D%3D"
                        frameborder="0"
                        allowFullScreen="true"></iframe>
                </div>
            </div> */}
        </>
    )
}
