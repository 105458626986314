import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import eyeHide from '../../../assets/showhide/eyeHide.png'
import eyeOpen from '../../../assets/showhide/eyeOpen.png'
import { Auth } from '../../../contexts/allContext'
import { api, frontEnd } from '../../../utils/apiToken'
import classes from './SubscriptionPlanPopUp.module.css'

export default function SubscriptionPlanPopUp(props) {
    const { activePopUp, setActivePopUp } = props

    const { stateAuth, dispatchAuth } = useContext(Auth)
    const [validationAlert, setAlert] = useState(false)

    const navigate = useNavigate()
    const [clicked, setClicked] = useState(false)
    const [userInfo, setUserInfo] = useState({
        email: '',
        password: '',
    })

    if (activePopUp) {
        document.body.classList.add(classes.activeModel)
    } else {
        document.body.classList.remove(classes.activeModel)
    }

    // if (stateAuth.auth) {
    //     setActivePopUp(false)
    // }

    useEffect(() => {
        if (stateAuth.auth) {
            setActivePopUp(false)
            navigate('/dashboard')
        }
    }, [navigate, stateAuth, activePopUp, setActivePopUp])

    const handleLogin = async (e) => {
        e.preventDefault()

        let logFetch = await axios.post(
            `${api}/login/login`,
            { email: userInfo.email, password: userInfo.password },
            {
                headers: {
                    'Access-Control-Allow-Origin': frontEnd,
                },
                withCredentials: true,
            }
        )

        let log = await logFetch.data

        if (log.Token) {
            dispatchAuth({ type: 'token', payload: log.Token })
        } else {
            setAlert(true)
            setTimeout(function () {
                setAlert(false)
            }, 3000)
        }
    }

    const handleSignUp = (role, type) => {
        navigate(`/register/${role}/${type}`, { state: { role: role, type: type } })
    }

    return (
        <>
            <div className={classes.loginPopUp}>
                <button className={classes.close} onClick={() => setActivePopUp(false)}>
                    X
                </button>
                <p className={classes.p1}>Please choose a subscription plan</p>

                {validationAlert && <p className={classes.alertMessage}>Invalid Username or Password!</p>}
                <div id="SUBS" className={classes.subscription}>
                    <h1>Subscription Plan for Data Terminal</h1>
                    <div>
                        <div>
                            <p>Basic</p>
                            <p>Perfect for everyone</p>
                            <p>Free</p>
                            <p></p>
                            <p>What you'll get</p>
                            <ul>
                                <li>Immediate Access to All Dashboards for 1 month</li>
                            </ul>
                            <button type="button" onClick={(e) => handleSignUp('individual_user', 'basic')}>
                                Subscribe
                            </button>
                        </div>

                        <div>
                            <p>Standard</p>
                            <p>Perfect for personal use</p>
                            <p className={classes.plan}>
                                $4 <span id={classes.plan}>/monthly or</span> $29
                                <span id={classes.plan}>/annually</span>
                                <br />
                                ৳299
                                <span id={classes.plan}>/monthly or </span>
                                ৳999
                                <span id={classes.plan}>/annually </span>
                            </p>
                            <p></p>
                            <p>What you'll get</p>
                            <ul>
                                <li>Full Access to All Dashboards</li>
                                <br></br>
                                <li>Export Graph as PDF & PNG </li>
                                <br></br>
                                <li>Download Data in CSV Format </li>
                                <br></br>
                                <li>Exclusive Customer Support </li>
                                <br></br>
                            </ul>
                            <button type="button" onClick={(e) => handleSignUp('standard_user', 'basic')}>
                                Subscribe
                            </button>
                        </div>

                        <div>
                            <p>Enterprise</p>
                            <p>Perfect for corporate use</p>
                            <p>Let's Talk</p>
                            <p></p>
                            <p>What you'll get</p>
                            <ul>
                                <li>Full Access to All Dashboards</li>
                                <br></br>
                                <li>Customized Dashboard </li>
                                <br></br>
                                <li>Export Graph as PDF & PNG </li>
                                <br></br>
                                <li>Download Data in CSV Format </li>
                                <br></br>
                                <li>Exclusive Customer Support </li>
                                <br></br>
                            </ul>
                            <a href="/#ContactUs">
                                <button type="button">Contact Us</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
