import React from 'react'
import ContactUsPopUp from './ContactUsPopUp/ContactUsPopUp'
import LoginValidationPopUp from './LoginValidationPopUp/LoginValidationPopUp'
import classes from './PopUp.module.css'
import SignUpPopUp from './SignUpPopUp/SignUpPopUp'
import SubscriptionPlanPopUp from './SubscriptionPlanPopUp/SubscriptionPlanPopUp'

export default function PopUp(props) {
    const { btnClickPopUp, activePopUp, setActivePopUp, payment } = props

    if (activePopUp) {
        document.body.classList.add(classes.activeModel)
    } else {
        document.body.classList.remove(classes.activeModel)
    }
    return (
        <>
            {activePopUp && (
                <>
                    <div className={classes.model}>
                        <div className={classes.overlay}></div>{' '}
                        {btnClickPopUp === '1' && (
                            <SignUpPopUp activePopUp={activePopUp} setActivePopUp={setActivePopUp} />
                        )}
                        {btnClickPopUp === '2' && (
                            <ContactUsPopUp
                                activePopUp={activePopUp}
                                setActivePopUp={setActivePopUp}
                                payment={payment}
                            />
                        )}
                        {btnClickPopUp === '3' && (
                            <SignUpPopUp activePopUp={activePopUp} setActivePopUp={setActivePopUp} />
                        )}
                        {btnClickPopUp === '4' && (
                            <SubscriptionPlanPopUp activePopUp={activePopUp} setActivePopUp={setActivePopUp} />
                        )}
                        {btnClickPopUp === '5' && (
                            <LoginValidationPopUp
                                activePopUp={activePopUp}
                                setActivePopUp={setActivePopUp}
                                payment={payment}
                            />
                        )}
                    </div>
                </>
            )}
        </>
    )
}
