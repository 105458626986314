import React from 'react'
import Footer from '../components/Footer/Footer'
import HowItWorks from '../components/HowItWorks/HowItWorks'
import Layout from '../components/Layout/Layout'

export default function HowItWorksPage() {
    return (
        <Layout>
            <HowItWorks />
            <Footer />
        </Layout>
    )
}
