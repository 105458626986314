import React, { useState } from 'react'
import { companyIndicatorList, economicIndicatorList, industryIndicatorList } from '../../variables/IndicatorList'
import PopUp from '../PopUp/PopUp'
import classes from './Indicators.module.css'

export default function Indicators() {
    const [activePopUp, setActivePopUp] = useState(false)
    const handleClick = (e, dataClicked) => {
        setActivePopUp(true)
    }
    return (
        <>
            <PopUp btnClickPopUp={'1'} activePopUp={activePopUp} setActivePopUp={setActivePopUp} />
            <div className={classes.indicators}>
                <h2>Indicators</h2>
                <div>
                    <div>
                        <p>Company Indicator</p>
                        <ul>
                            {companyIndicatorList.map((data, index) => {
                                return (
                                    <li
                                        key={index}
                                        onClick={(e) => {
                                            handleClick(e, data)
                                        }}>
                                        {data}
                                    </li>
                                )
                            })}
                        </ul>
                        <button
                            type="button"
                            onClick={(e) => {
                                handleClick(e)
                            }}>
                            More +
                        </button>
                    </div>
                    <div>
                        <p>Industry Indicator</p>
                        <ul>
                            {industryIndicatorList.map((data, index) => {
                                return (
                                    <li
                                        key={index}
                                        onClick={(e) => {
                                            handleClick(e, data)
                                        }}>
                                        {data}
                                    </li>
                                )
                            })}
                        </ul>
                        <button
                            type="button"
                            onClick={(e) => {
                                handleClick(e)
                            }}>
                            More +
                        </button>
                    </div>
                    <div>
                        <p>Economic Indicator</p>
                        <ul>
                            {economicIndicatorList.map((data, index) => {
                                return (
                                    <li
                                        key={index}
                                        onClick={(e) => {
                                            handleClick(e, data)
                                        }}>
                                        {data}
                                    </li>
                                )
                            })}
                        </ul>
                        <button
                            type="button"
                            onClick={(e) => {
                                handleClick(e)
                            }}>
                            More +
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
