import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { CompanyDatabase, IndustryDescription } from '../components'
import Footer from '../components/Footer/Footer'
import Layout from '../components/Layout/Layout'
import { Auth } from '../contexts/allContext'
import { api, frontEnd } from '../utils/apiToken'
import { companyCountryList, industryList, subIndustryList } from '../utils/industry'

export default function CompanyDatabasePage() {
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token
    const [search, setSearch] = useState('')
    const [data, setData] = useState([])
    const [countData, setCountData] = useState('')
    const [loading, setLoading] = useState(true)

    const [industry, setIndustry] = useState('')
    const [subIndustry, setSubIndustry] = useState('')
    const [country, setCountry] = useState('')
    const [industryOptions, setIndustryOptions] = useState([])
    const [subIndustryOptions, setSubIndustryOptions] = useState([])
    const [countryOptions, setCountryOptions] = useState([])

    const [page, setPage] = useState(20)
    const [pageSkip, setPageSkip] = useState(0)
    const [slNo, setSlNo] = useState(1)

    const [industryInd, setIndustryInd] = useState('')
    const [indData, setIndData] = useState([])
    const [indCount, setIndCount] = useState([])
    const [indLimit, setIndLimit] = useState(100)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const company_data = await axios.get(
                `${api}/company_database/company_data/?company=${search}&industry=${
                    industry === '' ? industry : industry.label
                }&subindustry=${subIndustry === '' ? subIndustry : subIndustry.label}&country=${
                    country === '' ? country : country.label
                }&skip=${pageSkip}&limit=${page}`
                // {
                //     headers: {
                //         'Access-Control-Allow-Origin': frontEnd,
                //         Authorization: `Bearer ${token}`,
                //     },
                //     withCredentials: true,
                // }
            )
            const company_Data = await company_data.data
            setData(company_Data.Data)
            setLoading(false)
            setCountData(company_Data.Count)
        }
        fetchData()
    }, [search, industry, subIndustry, country, pageSkip, page, token])

    const showDataDetails = (companyName) => {
        // navigate(`/CompanyDatabase/${companyName}`, { state: { name: companyName } })
    }

    const previousHandle = () => {
        if (pageSkip > 0) {
            setSlNo(slNo - page)
            setPageSkip(pageSkip - page)
        }
    }

    const nextHandle = async () => {
        if (pageSkip < Math.floor(countData / page) * page) {
            setSlNo(slNo + page)
            setPageSkip(pageSkip + page)
        }
    }

    const handleSearch = () => {
        setPageSkip(0)
        setSlNo(1)
    }

    useEffect(() => {
        const fetchData = async () => {
            const industryOptns = []
            industryList.filter((e) => industryOptns.push({ label: e, value: e }))
            setIndustryOptions(industryOptns)
        }
        fetchData()
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const subIndustryOptns = []
            subIndustryList.filter((e) => subIndustryOptns.push({ label: e, value: e }))
            setSubIndustryOptions(subIndustryOptns)
        }
        fetchData()
    }, [industry])

    useEffect(() => {
        const fetchData = async () => {
            const countryOptns = []
            companyCountryList.filter((e) => countryOptns.push({ label: e, value: e }))
            setCountryOptions(countryOptns)
        }
        fetchData()
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const industryDesc = await axios.get(
                `${api}/company_database/industry_description/?industry=${
                    industryInd === '' ? industryInd : industryInd.label
                }&skip=0&limit=${100}`
            )
            const industry_desc = await industryDesc.data
            setIndData(industry_desc.Data)
            setLoading(false)
            setIndCount(industry_desc.Count)
        }
        fetchData()
    }, [industryInd, indLimit])

    useEffect(() => {
        indCount && setIndLimit(indCount)
    }, [indCount])

    return (
        <Layout>
            <CompanyDatabase
                search={search}
                setSearch={setSearch}
                data={data}
                countData={countData}
                industry={industry}
                setIndustry={setIndustry}
                subIndustry={subIndustry}
                setSubIndustry={setSubIndustry}
                industryOptions={industryOptions}
                subIndustryOptions={subIndustryOptions}
                country={country}
                setCountry={setCountry}
                countryOptions={countryOptions}
                loading={loading}
                page={page}
                pageSkip={pageSkip}
                setPageSkip={setPageSkip}
                slNo={slNo}
                setSlNo={setSlNo}
                handleSearch={handleSearch}
                previousHandle={previousHandle}
                nextHandle={nextHandle}
                showDataDetails={showDataDetails}
            />
            <IndustryDescription
                indData={indData}
                industryInd={industryInd}
                setIndustryInd={setIndustryInd}
                industryOptions={industryOptions}
            />
            <Footer />
        </Layout>
    )
}
