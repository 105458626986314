import React from 'react'
import { useNavigate } from 'react-router'
import intro_video from '../../assets/video/intro.mp4'
import intro_video1 from '../../assets/video/intro.ogv'
import landingImage from '../../assets/video/landing_image.png'
import classes from './LandingView.module.css'

export default function LandingView() {
    const navigate = useNavigate()

    return (
        <>
            <div id="LandingView" className={classes.LandingPage}>
                <div>
                    <p>
                        Data<span style={{ color: 'var(--secondary)' }}>Terminal</span>
                    </p>
                    <p>Bangladesh's First Data Portal For Company Data, Industry Data & Economic Data</p>
                    <button
                        type="button"
                        onClick={() =>
                            navigate('/register/individual_user/basic', {
                                state: { role: 'individual_user', type: 'basic' },
                            })
                        }>
                        Start Your Free Trial &#x27F6;
                    </button>
                </div>
                <div>
                    <img src={landingImage} alt="" />
                    {/* <video src={intro_video} preload="auto" autoPlay loop muted /> */}
                    {/* <video poster={landingImage} autoPlay loop mute controls controlsList="nodownload">
                        <source src={intro_video} type="video/mp4" />
                    </video> */}
                    {/* <video controls>
                        <source src={intro_video} type="video/mp4" />
                    </video> */}

                    {/* <video src={intro_video} width="600" height="300" controls="controls" autoplay="true" /> */}
                </div>
            </div>
        </>
    )
}
