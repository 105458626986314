import { useReducer } from 'react'
import { Route, Routes } from 'react-router'
// import { SelectDashboardProvider } from './contexts/SelectDashboardProvider'
import { Auth, UserInfo } from './contexts/allContext'
import { authReducer, authState } from './contexts/reducer/authReducer'
import { userReducer, userState } from './contexts/reducer/userReducer'
import CompanyDatabasePage from './pages/CompanyDatabasePage'
// import { SidebarProvider } from './contexts/sidebarContext'
import DashboardPage from './pages/DashboardPage'
import DataList from './pages/DataListPage'
// import ProtectedRoute from "./routes/ProtectedRoute";
import HomePage from './pages/HomePage'
import HowItWorksPage from './pages/HowItWorksPage'
import IndicatorPage from './pages/IndicatorPage'
import LoginPage from './pages/LoginPage'
import PaymentProcessPage from './pages/PaymentProcessPage'
import RegisterPage from './pages/RegisterPage'
import TermsConditionsPage from './pages/TermsConditionsPage'
import ProtectedRoute from './routes/ProtectedRoute'

// const api = process.env.REACT_APP_BASE_URL
// const frontEnd = process.env.REACT_APP_CLIENT_URL

function App() {
    const [stateAuth, dispatchAuth] = useReducer(authReducer, authState)
    const [stateUser, dispatchUser] = useReducer(userReducer, userState)

    // useEffect(() => {
    //     const test = async () => {
    //         let logFetch = await axios.get(`${api}/login/auth`)
    //         console.log(logFetch)
    //     }
    //     test()
    // }, [])

    return (
        <Auth.Provider value={{ stateAuth, dispatchAuth }}>
            <UserInfo.Provider value={{ stateUser, dispatchUser }}>
                <Routes>
                    <Route path="/register/:role" element={<RegisterPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="" element={<HomePage />} />
                    <Route path="/how-it-works" element={<HowItWorksPage />} />
                    <Route path="/indicators" element={<IndicatorPage />} />
                    <Route path="/terms-and-conditions" element={<TermsConditionsPage />} />
                    <Route path="/payment-procedure" element={<PaymentProcessPage />} />
                    <Route path="/data-list" element={<DataList />} />
                    <Route path="company-database" element={<CompanyDatabasePage />} />

                    <Route path="/*" element={<ProtectedRoute />}>
                        <Route path="dashboard" element={<DashboardPage />} />
                    </Route>
                </Routes>
            </UserInfo.Provider>
        </Auth.Provider>
    )
}

export default App
