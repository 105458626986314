import 'chart.js/auto'
import React, { useContext, useRef, useState } from 'react'
import { useCallback } from 'react'
import { Line } from 'react-chartjs-2'
import { CSVLink } from 'react-csv'
import { useReactToPrint } from 'react-to-print'
import downloadCsvIcon from '../../../assets/downloadIcon/csv.png'
import downloadImageIcon from '../../../assets/downloadIcon/graph.png'
import downloadImageIconPdf from '../../../assets/downloadIcon/pdf.png'
import { UserInfo } from '../../../contexts/allContext'
import classes from './LineChart.module.css'

export default function LineChartMonthly(props) {
    const {
        dataForExport,
        dash,
        search,
        jan,
        feb,
        mar,
        apr,
        may,
        jun,
        jul,
        aug,
        sep,
        oct,
        nov,
        dec,
        dataValueMonthX,
        legendY,
        legendX,
        timeline,
    } = props

    const labels = dataValueMonthX ? dataValueMonthX : ''
    const data = {
        labels,
        datasets: [
            {
                label: jan ? 'Jan' : 'No data found',
                data: jan ? jan : '',
                backgroundColor: ['#100b95'],
                borderColor: ['#100b95'],
            },
            {
                label: feb ? 'Feb' : 'No data found',
                data: feb ? feb : '',
                backgroundColor: ['#4169e1'],
                borderColor: ['#4169e1'],
            },
            {
                label: mar ? 'Mar' : 'No data found',
                data: mar ? mar : '',
                backgroundColor: ['#1e90ff'],
                borderColor: ['#1e90ff'],
            },
            {
                label: apr ? 'Apr' : 'No data found',
                data: apr ? apr : '',
                backgroundColor: ['#6495ed'],
                borderColor: ['#6495ed'],
            },
            {
                label: may ? 'May' : 'No data found',
                data: may ? may : '',
                backgroundColor: ['#2C74B3'],
                borderColor: ['#2C74B3'],
            },
            {
                label: jun ? 'Jun' : 'No data found',
                data: jun ? jun : '',
                backgroundColor: ['#87cefa'],
                borderColor: ['#87cefa'],
            },
            {
                label: jul ? 'Jul' : 'No data found',
                data: jul ? jul : '',
                backgroundColor: ['#b0c4de'],
                borderColor: ['#b0c4de'],
            },
            {
                label: aug ? 'Aug' : 'No data found',
                data: aug ? aug : '',
                backgroundColor: ['#5DA7DB'],
                borderColor: ['#5DA7DB'],
            },
            {
                label: sep ? 'Sep' : 'No data found',
                data: sep ? sep : '',
                backgroundColor: ['#a2add0'],
                borderColor: ['#a2add0'],
            },
            {
                label: oct ? 'Oct' : 'No data found',
                data: oct ? oct : '',
                backgroundColor: ['#71a6d2'],
                borderColor: ['#71a6d2'],
            },
            {
                label: nov ? 'Nov' : 'No data found',
                data: nov ? nov : '',
                backgroundColor: ['#0D4C92'],
                borderColor: ['#0D4C92'],
            },
            {
                label: dec ? 'Dec' : 'No data found',
                data: dec ? dec : '',
                backgroundColor: ['#9bc4e2'],
                borderColor: ['#9bc4e2'],
            },
        ],
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            colors: {
                enabled: false,
            },
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: search && `${search} (${legendX})`,
                font: {
                    size: 12,
                },
            },
            backgroundColor: 'rgba(255, 99, 132)',
        },
        scales: {
            x: {
                // grid: {
                //     borderDash: [1, 20],
                //     color: 'grey',
                // },
                title: {
                    display: true,
                    text: legendY ? `Source: ${legendY}` : '',
                    font: {
                        size: 12,
                    },
                },
            },

            y: {
                grid: {
                    color: 'white',
                },
                title: {
                    display: true,
                    text: legendX ? `Unit of Measurement: ${legendX}` : '',
                    font: {
                        size: 12,
                    },
                },
            },
        },
    }

    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info

    const [validationAlert, setAlert] = useState(false)

    const handlePopUpMessage = () => {
        setAlert(true)
        setTimeout(function () {
            setAlert(false)
        }, 3000)
    }

    const [validationAlertStandard, setAlertStandard] = useState(false)

    const handlePopUpMessageStandard = () => {
        setAlertStandard(true)
        setTimeout(function () {
            setAlertStandard(false)
        }, 3000)
    }

    const ref = useRef(null)
    const downloadImage = useCallback(() => {
        const link = document.createElement('a')
        link.download = 'chart.png'
        link.href = ref.current.toBase64Image()
        link.click()
    }, [])

    const pdfRef = useRef(null)
    const downloadImagePdf = useReactToPrint({
        content: () => pdfRef.current,
        documentTitle: 'graph',
        // onAfterPrint: () => alert('print success'),
    })

    return (
        <>
            <div className={classes.chartWrapper} ref={pdfRef}>
                <Line ref={ref} data={data} options={options} />
            </div>
            <div className={classes.download}>
                <CSVLink
                    onClick={(e) => {
                        if (userInfo.restriction === 1) {
                            return true
                        } else if (
                            (userInfo.role === 'standard_y' || userInfo.role === 'standard_m') &&
                            userInfo.restriction === 0
                        ) {
                            handlePopUpMessageStandard()
                            return false
                        } else {
                            handlePopUpMessage()
                            return false
                        }
                    }}
                    className={classes.btnCsvExport}
                    data={dataForExport ? dataForExport : ''}
                    filename={`${dash}_${search}_${timeline}`}>
                    <img src={downloadCsvIcon} alt="" />
                    <p>Export Data as csv</p>
                </CSVLink>
                {/* onClick={() => downloadImage()}
                onClick={() => downloadImagePdf()} */}
                <button
                    onClick={() => {
                        if (userInfo.restriction === 1) {
                            downloadImage()
                        } else if (
                            (userInfo.role === 'standard_y' || userInfo.role === 'standard_m') &&
                            userInfo.restriction === 0
                        ) {
                            handlePopUpMessageStandard()
                        } else {
                            handlePopUpMessage()
                        }
                    }}>
                    <img src={downloadImageIcon} alt="" />
                    <p>Export Graph as png</p>
                </button>
                <button
                    onClick={() => {
                        if (
                            userInfo.restriction === 1 &&
                            (userInfo.role === 'standard_y' || userInfo.role === 'standard_m')
                        ) {
                            downloadImagePdf()
                        } else if (
                            (userInfo.role === 'standard_y' || userInfo.role === 'standard_m') &&
                            userInfo.restriction === 0
                        ) {
                            handlePopUpMessageStandard()
                        } else {
                            handlePopUpMessage()
                        }
                    }}>
                    <img src={downloadImageIconPdf} alt="" />
                    <p>Export Graph as pdf</p>
                </button>
                {validationAlert && (
                    <p id={classes.popUpMessage}>Please upgrade to standard account to avail this service.</p>
                )}
                {validationAlertStandard && (
                    <p id={classes.popUpMessage}>Please wait for your payment confirmation email.</p>
                )}
            </div>
        </>
    )
}
