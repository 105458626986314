import React, { useState } from 'react'
import { FaDownload } from 'react-icons/fa'
import { FaRectangleList } from 'react-icons/fa6'
import { IoListCircle } from 'react-icons/io5'
import { PopUpTemplate, SelectField, Table } from '../Resource'
import Contact from './Contact/Contact'
import classes from './IndustryDescription.module.css'

export default function IndustryDescription(props) {
    const [popUp, setPopUp] = useState(false)
    const [id, setId] = useState(false)
    return (
        <div className={classes.wrapper}>
            <div className={classes.selectField}>
                <div className={classes.title}>
                    <FaRectangleList className={classes.listIcon} />
                    Industry List
                </div>
                <SelectField
                    search_by="-- Select Industry --"
                    borderColor={'lightgrey'}
                    borderHover={'grey'}
                    searchable={true}
                    clearable={true}
                    options={props.industryOptions}
                    value={props.industryInd}
                    selectOnChange={(e) => {
                        if (e) {
                            props.setIndustryInd(e)
                        } else {
                            props.setIndustryInd('')
                        }
                    }}
                />
            </div>
            <div className={classes.desk}>
                <Table scroll={false}>
                    <thead>
                        <tr className={classes.tr}>
                            <th>#</th>
                            <th>Industry</th>
                            <th>Description</th>
                            <th>No. of Company</th>
                            <th>Last Updated</th>
                            <th>File Format</th>
                            <th id={classes.download}>Download</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.indData &&
                            props.indData.map((data, index) => (
                                <tr className={classes.tr} key={index}>
                                    <td>{index + 1}</td>
                                    <td>{data.industry}</td>
                                    <td id={classes.description}>{data.description}</td>
                                    <td>{data.number_of_company}</td>
                                    <td>{data.last_updated}</td>
                                    <td>{data.file_format}</td>
                                    <td id={classes.download}>
                                        <FaDownload
                                            onClick={() => {
                                                setId(index)
                                                setPopUp(true)
                                            }}
                                            className={classes.downloadBtn}
                                        />
                                        {popUp && index === id && (
                                            <PopUpTemplate>
                                                <Contact setPopUp={setPopUp} />
                                            </PopUpTemplate>
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </div>
            <div className={classes.mob}>
                <Table scroll={true}>
                    <thead>
                        <tr className={classes.tr}>
                            <th>#</th>
                            <th>Industry</th>
                            <th>Description</th>
                            <th>No. of Company</th>
                            <th>Last Updated</th>
                            <th>File Format</th>
                            <th id={classes.download}>Download</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.indData &&
                            props.indData.map((data, index) => (
                                <tr className={classes.tr} key={index}>
                                    <td>{index + 1}</td>
                                    <td>{data.industry}</td>
                                    <td id={classes.description}>{data.description}</td>
                                    <td>{data.number_of_company}</td>
                                    <td>{data.last_updated}</td>
                                    <td>{data.file_format}</td>
                                    <td id={classes.download}>
                                        <FaDownload
                                            onClick={() => {
                                                setId(index)
                                                setPopUp(true)
                                            }}
                                            className={classes.downloadBtn}
                                        />
                                        {popUp && index === id && (
                                            <PopUpTemplate>
                                                <Contact setPopUp={setPopUp} />
                                            </PopUpTemplate>
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}
