export const economicIndicatorList = [
    'Account Balance',
    'Current Account Balance',
    'Balance of Payment (BOP)',
    'Outstanding External Debt',
    'Foreign Exchange Reserve',
    'Agriculture sector contribution to GDP (Percentage)',
    'Consumer Price Index (CPI)',
    'Export - Total',
    'Total Services Export',
    'Export to Australia',
    'Export to UK',
    'FDI',
    'Inflation',
    'Import',
    'Import from Canada',
    'Import from USA',
    'Remittance',
    'Remittance from Germany',
    'Remittance from Saudi Arabia (KSA)',
    'FDI as Percentage of GDP',
    'Foreign Aid Disbursement',
    'Foreign Aid for NGO - Commitment',
    'Foreign Direct Investment (FDI) Inflow',
    'Gross Domestic Product (GDP) Growth Rate (Percentage)',
    'Government Debt (Percent of GDP)',
    'Gross Domestic Product (GDP)',
    'Industry Sector Contribution (Percentage) to GDP',
    'Interest Payment by Government',
    'Net Investment in Savings Certificate',
    'Net Sales of Savings Certificate (NSC)',
    'Outward FDI from Bangladesh',
    'Per capita Gross National Income (GNI)',
    'Private Sector Credit Growth (Percentage)',
    'Public Investment to GDP Ratio (Percentage)',
    'Revenue Collection by NBR',
    'Tax Revenue (Percent of GDP)',
    'Tax Revenue from DSE',
    'Trade Balance',
    'Trade deficit',
    'Wage Rate Index (WRI)',
    'Weighted Average Exchange Rate (Average)',
    'Loan or Credit in Private Sector',
    'Business Confidence Index (BCI) - Profitability',
    'Present Business Status Index (PBSI) - Investment',
    'Water Tariff - Residential price',
    'Non - NBR Tax Revenue',
    'Non- Tax Revenue',
    'Tax Revenue',
    'Annual Development Programme (ADP) Allocation',
    'Annual Development Programme (ADP) Expenditure',
]

export const industryIndicatorList = [
    'Mobile Internet User',
    'Internet User - Total',
    'Mobile Subscriber',
    'Agent Banking Transaction - Total Loan Disbursement',
    'MFS Transaction - Amount',
    'Internet Banking Transaction - Amount',
    'Private Sector Credit Growth - NBFI',
    'Knitwear Export',
    'Woven Export',
    'Net Profit - Banking Sector',
    'Aman Rice Production - Volume',
    'AUS Rice Production - Volume',
    'Non performing loan (NPL) Percentage',
    'Carpet Export',
    'Currency Outside Bank',
    'Cotton Production - Volume',
    'Edible Oil Import',
    'Landphone connection',
    'Fertilizer Import',
    'Food Grains Import',
    'Frozen Fish Export',
    'Hilsa Fish Production - Volume',
    'Life Insurance Premium - Gross',
    'Non Life Insurance Premium - Gross',
    'Pharmaceutical Import',
    'Dyeing and Tanning Materials Import',
    'Export of Light Engineering Products',
    'Total Savings - MFI',
    'Value Added by RMG',
    'Raw Materials Import for RMG',
    'RMG Export - Total',
    'Net RMG Export (Subtracting Raw Materials Import for RMG)',
    'Value Added by RMG',
    'RMG Export to Cyprus',
    'RMG Export to EU',
    'Woven Export to Australia',
    'Woven Export to Brazil',
    'Woven Export to USA',
    'Knitwear Export to Canada',
    'Knitwear Export to India',
    'Knitwear Export to Turkey',
    'Knitwear Export to USA',
    'Export- Home Textile',
    'Building Materials Export',
    'Di-Ammonium Phosphate (DAP) Production',
    'Urea Import',
    'Registered Vehicles - Total',
    'Number of Initial Public Offering (IPO)',
    'Initial Public Offering (IPO) Size',
    'Microfinance or Microcredit Loan Client or Member',
    'Shrimp Export',
    'Frozen Crab Export',
    'Tea Export',
]

export const companyIndicatorList = [
    'Dividend Payout',
    'Profit After Tax or Net Profit',
    'Operating Profit or Operating Income or Total Income',
    'Net Profit',
    'Total Revenue or Sales Revenue or Turnover',
    'Earnings per share (EPS)',
    "Shareholder's Equity or Fund",
    'Net Asset Value (NAV) per share',
    'Mobile Subscriber',
    'Total Dividend (Percentage)',
    'Cash Dividend',
    'Total Assets',
    'Revenue',
    'Loans and Advances',
    'Total Deposit',
    'Cost to Income Ratio',
    'Return on Assets (ROA)',
    'Capital Adequacy Ratio (CAR) or Capital to Risk Weighted Asset Ratio (CRAR)',
    'Return on Equity (ROE)',
    'Contribution to Government or National Exchequer (Percentage)',
    'SME Loan Disbursement',
    'Retail Loan Disbursement',
    'Corporate Loan Disbursement',
    'Non-performing Loan (NPL) or Classified Loan(CL) Ratio',
    'Authorized Capital',
    'Current Liabilities',
    'Current Assets',
    'Expenses Ratio',
    'Price Earnings (P or E) Ratio',
    "Shareholder's Equity or Fund",
    'Total Claims',
    'Claim Paid',
    'Fixed Deposit Receipts',
    'Total Revenue',
    'Operating Income',
    'Total Capital',
    'Paid-up Capital',
    'Capital Surplus',
    'Profit Before Tax',
    'Fixed Assets',
    'Total Gross Premium Income',
    'Net Premium Income',
    'Gross Claim Payment',
    'Net Claims Payment',
    'Underwriting Profit',
    'Investment and other Income',
    'Market Capitalization',
    'Debt to Equity Ratio',
    'Liabilities and Equity',
    'Capital Adequacy Ratio (CAR) or Capital to Risk Weighted Asset Ratio (CRAR)',
    'NOCFPS',
    'EBITDA',
]
