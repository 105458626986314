import React from 'react'
import Footer from '../components/Footer/Footer'
import Indicators from '../components/Indicators/Indicators'
import Layout from '../components/Layout/Layout'

export default function IndicatorPage() {
    return (
        <Layout>
            <Indicators />
            <Footer />
        </Layout>
    )
}
