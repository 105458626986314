import React from 'react'

export function AboutEconomicDashboard() {
    return (
        <>
            Economic Dashboard provides data on 12,000+ data series of Bangladesh's economy. These indicators provide up
            to 20+ years of data that have been synchronized month-wise, calendar year-wise, and fiscal year-wise.
        </>
    )
}

export function AboutIndustryDashboard() {
    return (
        <>
            Industry Dashboard offers 15,000+ macroeconomic and microeconomics data data series of Bangladesh from 60+
            sources across 50+ sectors of Bangladesh.
            <br />
            <br />
            It provides a comprehensive view of the performance of Bangladesh's 50+ industries.
        </>
    )
}

export function AboutCompanyDashboard() {
    return (
        <>
            Company Dashboard provides 95,000+ data indicators of 500+ listed and non-listed companies from 50+ sectors
            of Bangladesh.
            <br />
            <br />
            With company dashboard, you'll be able to find the 20+ years of financial and non-financial performance of a
            company.
        </>
    )
}
