import 'chart.js/auto'
import React, { useCallback, useContext, useRef, useState } from 'react'
import { Scatter } from 'react-chartjs-2'
import { CSVLink } from 'react-csv'
import { useReactToPrint } from 'react-to-print'
import downloadCsvIcon from '../../../assets/downloadIcon/csv.png'
import downloadImageIcon from '../../../assets/downloadIcon/graph.png'
import downloadImageIconPdf from '../../../assets/downloadIcon/pdf.png'
import { UserInfo } from '../../../contexts/allContext'
import classes from './ScatterChart.module.css'

export default function ScatterChart(props) {
    const { dataForExport, dash, search, dataValueY, dataValueX, legendY, legendX, timeline } = props

    const labels = dataValueX ? dataValueX : ''

    const data = {
        // labels,
        datasets: [
            {
                label: timeline ? timeline : 'No data found',
                data: dataValueY
                    ? dataValueY
                    : [
                          {
                              x: 10,
                              y: 20,
                          },
                          {
                              x: 15,
                              y: 10,
                          },
                      ],
                backgroundColor: ['#100b95'],
            },
        ],
    }

    const options = {
        // indexAxis: 'x',
        // elements: {
        //     scatter: {
        //         borderWidth: 2,
        //     },
        // },
        // responsive: true,
        // maintainAspectRatio: false,
        // plugins: {
        //     colors: {
        //         enabled: false,
        //     },
        //     legend: {
        //         position: 'top',
        //     },
        //     title: {
        //         display: true,
        //         text: search && `${search} (${legendX})`,
        //         font: {
        //             size: 12,
        //         },
        //     },
        //     backgroundColor: 'rgba(255, 99, 132)',
        // },
        scales: {
            x: {
                // grid: {
                //     borderDash: [1, 20],
                //     color: 'rgba(0, 0, 0, 0)',
                // },
                // title: {
                //     display: true,
                //     text: legendY ? `Source: ${legendY}` : '',
                //     font: {
                //         size: 12,
                //     },
                // },
                type: 'linear',
                position: 'bottom',
            },

            // y: {
            //     // grid: {
            //     //     color: 'white',
            //     // },
            //     title: {
            //         display: true,
            //         text: legendX ? `Unit of Measurement: ${legendX}` : '',
            //         font: {
            //             size: 12,
            //         },
            //     },
            // },
        },
    }

    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info

    const [validationAlert, setAlert] = useState(false)

    const handlePopUpMessage = () => {
        setAlert(true)
        setTimeout(function () {
            setAlert(false)
        }, 3000)
    }

    const [validationAlertStandard, setAlertStandard] = useState(false)

    const handlePopUpMessageStandard = () => {
        setAlertStandard(true)
        setTimeout(function () {
            setAlertStandard(false)
        }, 3000)
    }

    const ref = useRef(null)
    const downloadImage = useCallback(() => {
        const link = document.createElement('a')
        link.download = 'chart.png'
        link.href = ref.current.toBase64Image()
        link.click()
    }, [])

    const pdfRef = useRef(null)
    const downloadImagePdf = useReactToPrint({
        content: () => pdfRef.current,
        documentTitle: 'graph',
        // onAfterPrint: () => alert('print success'),
    })

    return (
        <>
            <div className={classes.chartWrapper} ref={pdfRef}>
                <Scatter ref={ref} data={data} options={options} />
            </div>
            <div className={classes.download}>
                <CSVLink
                    onClick={(e) => {
                        if (userInfo.restriction === 1) {
                            return true
                        } else if (
                            (userInfo.role === 'standard_y' || userInfo.role === 'standard_m') &&
                            userInfo.restriction === 0
                        ) {
                            handlePopUpMessageStandard()
                            return false
                        } else {
                            handlePopUpMessage()
                            return false
                        }
                    }}
                    className={classes.btnCsvExport}
                    data={dataForExport ? dataForExport : ''}
                    filename={`${dash}_${search}_${timeline}`}>
                    <img src={downloadCsvIcon} alt="" />
                    <p>Export Data as csv</p>
                </CSVLink>
                {/* onClick={() => downloadImage()} */}
                {/* onClick={() => downloadImagePdf()} */}
                <button
                    onClick={() => {
                        if (userInfo.restriction === 1) {
                            downloadImage()
                        } else if (
                            (userInfo.role === 'standard_y' || userInfo.role === 'standard_m') &&
                            userInfo.restriction === 0
                        ) {
                            handlePopUpMessageStandard()
                        } else {
                            handlePopUpMessage()
                        }
                    }}>
                    <img src={downloadImageIcon} alt="" />
                    <p>Export Graph as png</p>
                </button>
                <button
                    onClick={() => {
                        if (userInfo.restriction === 1) {
                            downloadImagePdf()
                        } else if (
                            (userInfo.role === 'standard_y' || userInfo.role === 'standard_m') &&
                            userInfo.restriction === 0
                        ) {
                            handlePopUpMessageStandard()
                        } else {
                            handlePopUpMessage()
                        }
                    }}>
                    <img src={downloadImageIconPdf} alt="" />
                    <p>Export Graph as pdf</p>
                </button>
                {validationAlert && (
                    <p id={classes.popUpMessage}>Please upgrade to standard account to avail this service.</p>
                )}
                {validationAlertStandard && (
                    <p id={classes.popUpMessage}>Please wait for your payment confirmation email.</p>
                )}
            </div>
        </>
    )
}
